<template>
  <card
    class="checkout-timeslots"
    v-bind="state"
    v-show="!timeslotsDisabled"
    @cancel="onCancel"
    @confirm="onConfirm">

    <template v-slot:header>
      <h2 class="checkout-card__title">{{ title }}</h2>

      <actions-button
        v-if="!active && !timeslotsDisabled"
        class="shop__cta"
        @click="onEdit"
        size="s"
      >{{$t('resto.change')}}</actions-button>
    </template>

    <!-- Read -->
    <template v-slot:read>
      <!-- Shop information -->
      <article class="checkout-card__article">
        <div class="checkout-card__illustration -has-figure">
          <ui-img
            :alt="name"
            class="checkout-card__figure"
            :src="shop.logo"
            v-if="shop.logo"
          />
        </div>

        <div class="checkout-card__inner">
          <div class="checkout-card__label">{{ name }}</div>

          <address class="checkout-card__address">
            <ui-icon glyph="location"/>
            <span>{{ address }}</span>
          </address>

          <a
            v-if="phone"
            :href="phoneLink"
            class="checkout-card__phone">
            <ui-icon glyph="phone"/>
            <span>{{ phone }}</span>
          </a>
        </div>
      </article>

      <!-- Timeslots -->
      <article
        class="checkout-card__article"
        v-if="timeslot && isTimezoneDifferent">
        <div class="checkout-card__inner">
          <notice-banner
            :title="$t('resto.timeslot_timezone_warning', { shop_gmt: shopGmtLabel, client_gmt: clientGmtLabel })"
            v-if="timeslot && isTimezoneDifferent"
          />
        </div>
      </article>

      <!-- Timeslots -->
      <article class="checkout-card__article" v-if="!timeslotsDisabled">
        <div class="checkout-card__illustration">
          <ui-icon glyph="time"></ui-icon>
        </div>

        <div class="checkout-card__inner">
          <div
            class="checkout-card__label"
            v-if="!isAsap">{{ value }}</div>

          <span
            class="checkout-card__value"
            v-if="isAsap"
          >{{ $t('resto.pickup_asap_label', { value: valueTime }) }}</span>
        </div>
      </article>
    </template>

    <!-- Edit -->
    <template v-slot:edit>
      <div
        class="checkout-card__banner"
        v-if="openingHours">
        <notice-banner
          v-scroll-reveal
          :description="openingHours"
        />
      </div>

      <!-- FLPOS Selection -->
      <article
        class="checkout-card__article"
        v-if="!hasMultipleFlposes || !hasFlposSelection">
        <div class="checkout-card__illustration -has-figure">
          <ui-img
            :alt="name"
            class="checkout-card__figure"
            :src="shop.logo"
            v-if="shop.logo"
          />
        </div>

        <div class="checkout-card__inner">
          <div class="checkout-card__label">{{ name }}</div>

          <address class="checkout-card__address">
            <ui-icon glyph="location"/>
            <span>{{ address }}</span>
          </address>
          <a
            v-if="phone"
            :href="phoneLink"
            class="checkout-card__phone">
            <ui-icon glyph="phone"/>
            <span>{{ phone }}</span>
          </a>
        </div>
      </article>

      <article
        class="checkout-card__article -column flow"
        v-else >
        <notice-banner
          v-if="hasFlposError"
          :description="$t('resto.checkout_review_select_flpos')"
          intent="danger"
        />

        <forms-choice
          :key="key"
          :disabled="loading"
          :options="flposesList"
          :value="selectedFlpos"
          @change="(v) => onChangeFlpos(v, true)"
          :errors="$basil.get(errors, 'flpos', [])"
        />
      </article>

      <!-- Date/Slot selection -->
      <article class="checkout-card__article -column flow">
        <notice-banner
          v-if="hasTimeslotError && !error500"
          :description="$t('resto.checkout_review_select_timeslot')"
          intent="danger"
        />

        <notice-banner
          v-if="!hasTimeslots && !error500"
          :description="$t('resto.no_timeslot_available')"
          intent="danger"
        />

        <notice-banner
          v-if="timeslot && isTimezoneDifferent"
          :title="$t('resto.timeslot_timezone_warning', { shop_gmt: shopGmtLabel, client_gmt: clientGmtLabel })"
        />

        <!-- :description="$t('resto.no_timeslot_available')" -->
        <notice-banner
          v-if="error500"
          intent="danger">
          <template #description>
            <div class="row">
              {{ $t('resto.error_timeslots_5xx', { status: errorStatus }) }}

              <actions-button
                :appearance="$pepper.Appearance.DEFAULT"
                icon-post="redo"
                :size="$pepper.Size.S"
                @click="reset"
              >{{ $t('resto.reload') }}</actions-button>
            </div>
          </template>
        </notice-banner>

        <notice-banner
          v-if="almostAtCapacity && !error500"
          :description="$t('resto.checkout_review_timeslot_almost_at_capacity')"
        />

        <notice-banner
          v-if="hasPreparationTime && !error500"
          :description="$t('resto.checkout_review_cart_has_preparation_time')"
        />

        <div 
          class="group" 
          v-if="!forceAsap">
          <!-- Date selection -->
          <forms-select
            id="checkout-card__date-selector"
            :disabled="loading || !isFlposSelected"
            :placeholder="$t('resto.checkout_review_select_day')"
            :options="dates"
            required
            @change="onChangeDate"
            v-model="date"
          >{{ $t('resto.timeslot_date') }}</forms-select>

          <!-- Slot selection -->
          <forms-select
            :disabled="loading || date == null || !isFlposSelected "
            :errors="$basil.get(state, 'errors.timeslot', [])"
            :options="timeslots"
            :placeholder="hasTimeslots ? $t('resto.timeslot_time') : $t('resto.checkout_review_no_timeslots')"
            required
            @change="onChangeTimeslot"
            v-model="timeslotValue"
          >{{ $t('resto.timeslot_time') }}</forms-select>
        </div>

        <notice-banner
          :description="$t('resto.checkout_review_asap_only', { value: $basil.get(timeslot, '__label__') })"
          v-else-if="!hasTimeslotError && !error500 && hasTimeslots && timeslot"
        />
      </article>
    </template>
  </card>
</template>

<script>
import {
  mapGetters,
  mapState
} from 'vuex'

import MixinServices from '../../mixins/services'
import Card from './card'

import UiImg from '@/components/ui/img'
import dayjs from 'dayjs'

export default {
  name: 'CheckoutTimeslotCard',

  components: {
    Card,
    UiImg,
  },
  extends: Card,

  inject: [
    '$checkout',
    '$embed'
  ],

  mixins: [
    MixinServices
  ],

  data() {
    return {
      key: 1,
      date: null,
      almostAtCapacity: false,
      error500: false,
      errorStatus: null,

      timeslotValue: null,
      timeslotAsap: false,
    }
  },

  computed:{
    ...mapState({
      flpos: state => state['sayl-front.checkout'].flpos,

      order: state => state['sayl-front.checkout'].order,

      checkoutLoading: state => state['sayl-front.checkout'].loading,
      service: state => state['sayl-front.service'].service,

      state: state => state['checkout'].timeslot,
      termState: state => state['checkout'].term,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    address() {
      let ret = this.$basil.get(this.selectedFlpos, 'value.address')
      return ret
    },

    clientGmtLabel() {
      const hoursOffset = new Date().getTimezoneOffset() / 60
      const prefix = hoursOffset > 0 ? '-' : '+'
      return `GMT${prefix}${Math.abs(hoursOffset)}`
    },

    dates() {
      let ret = []

      if(this.$checkout.timeslots._days.length > 0) {
        this.$checkout.timeslots._days.forEach((day, i) => {
          let label = this.$t(day.label) + ' ' + this.$date(new Date(day.date), 'short')
          let date = day.timestamp
          ret.push({ label: label, value: date, disabled: !day.is_active })
        })
      }

      return ret
    },

    flposesList() {
      let ret = this.$basil.get(this.shop, 'flposes', []);
      ret = ret.filter(s => s.services.includes(this.serviceName))
      return ret.map((flpos) => Object.assign({ label: flpos.name, description: flpos.address, value: flpos }, flpos))
    },

    forceAsap() {
      let s = this.$basil.get(this.oat, 'is_pickup_asap_only')
      let ret = null
      let service = this.serviceName === 'pickup' ? 'base' : this.serviceName

      if(service) {
        ret = s && this.$basil.get(s, service)
        if(ret == null) {
          ret = s?.all ?? false
        }
      }
      return this.isAsapEnabled && ret
    },

    hasFlpos() {
      return this.selectedFlpos != null
    },

    hasFlposError() {
      return this.errored && !this.$basil.isNil(this.$basil.get(this.errors, 'flpos'))
    },

    hasMultipleFlposes() {
      return this.flposesList.length > 1
    },

    hasFlposSelection() {
      return this.$basil.get(this.oat, 'has_fl_selection', true)
    },

    hasPreparationTime() {
      let ret = 0

      let items = this.$basil.get(this.order, 'order_items') || []

      items.forEach(o => {
        let pt = this.$basil.get(o, 'preparation_time', null)
        ret = !this.$basil.isNil(pt) && pt > ret ? pt : ret
      })

      return ret > 0
    },

    hasTimeslots() {
      return this.loading || (this.timeslots && this.timeslots.filter(t => !t.disabled).length > 0)
    },

    hasTimeslotError() {
      return this.errored && !this.$basil.isNil(this.$basil.get(this.errors, 'timeslot'))
    },

    isAsap() {
      return this.$basil.get(this.order, 'pickup_asap')
    },

    isAsapEnabled() {
      let s = this.$basil.get(this.oat, 'is_pickup_asap_enabled')
      let ret = null
      let service = this.serviceName === 'pickup' ? 'base' : this.serviceName

      if(service) {
        ret = s && this.$basil.get(s, service)
        if(ret == null) {
          ret = s?.all ?? false
        }
      }

      return ret
    },

    isFlposSelected() {
      return !this.$basil.isNil(this.selectedFlpos)
    },

    isTimezoneDifferent() {
      if(this.timeslot) {
        let shopTimezone = this.timeslot.timezone.offset
        let clientTimezone = (-1) * new Date().getTimezoneOffset() * 60
        return shopTimezone !== clientTimezone
      }
    },

    name() {
      return this.$basil.get(this.selectedFlpos, 'value.name')
    },

    orderTimeslot() {
      return this.$basil.get(this.order, 'timeslot', null)
    },

    phone() {
      return this.$basil.get(this.selectedFlpos, 'value.phone')
    },

    phoneLink() {
      return `tel://${this.phone}`
    },

    selectedFlpos() {
      let ret = this.flposesList.find(fl => this.flpos != null ? this.flpos.id === fl.value.id : null)
      if(!this.hasFlposSelection || this.$basil.isNil(this.$basil.get(this.order, 'fulfillment_location_id'))) {
        ret = this.flposesList[0];
      }
      return ret || null
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    shopGmtLabel() {
      if(this.timeslot) {
        const hoursOffset = this.timeslot.timezone.offset / (60*60);
        const prefix = hoursOffset > 0 ? '+' : '-';
        return `GMT${prefix}${Math.abs(hoursOffset)}`;
      }
      return null
    },

    timeslotsDisabled() {
      return this.selectedFlpos !== null ?
        this.selectedFlpos._timeslotsDisabled :
        false
    },

    timeslots() {
      let ret = []

      if (!this.$basil.isNil(this._timeslots)){
        let asapFound = false

        this._timeslots.forEach((t) => {
          let r = JSON.parse(JSON.stringify(t))

          if(!asapFound && t.has_capacity && this.isAsapEnabled) {
            let a = JSON.parse(JSON.stringify(r))
            a.asap = true
            a.label = this.$t('resto.timeslot_asap', { value: r.label })
            a.__label__ = r.label
            a.value = a.value + ':asap'
            asapFound = true

            let today = dayjs()
            let offset =  this.$basil.get(t, 'timezone.offset', 0)
            let dateParsed =  this.date * 1000

            if(offset) {
              dateParsed += offset  * 1000
            }

            let isToday = today.isSame(dayjs(dateParsed), 'day')
            if(isToday) {
              ret.unshift(a)
            }

            if(isToday && !this.loading && !this.timeslotValue) {
              this.timeslotValue = a.value
              this.onChangeTimeslot()
            }
          }

          if(!t.has_capacity) {
            r.label += ` (${this.$t('resto.timeslot_no_capacity')})`
          }

          ret.push({ ...r, disabled: !r.has_capacity })
        })
      }

      return ret
    },

    _timeslots() {
      return this.$checkout.timeslots.timeslots
    },

    _timeslot() {
      return this.$checkout.timeslots.timeslot
    },

    timeslot() {
      let ret = null
      if(this.timeslotValue && this.timeslots) {
        ret = this.timeslots.find(t => parseInt(t.value, 10) === parseInt(this.timeslotValue))
      }
      return ret
    },

    title() {
      let ret = this.$t('resto.checkout_review_timeslots')

      if (this.timeslotsDisabled) {
        ret = this.$t('resto.checkout_review_timeslots_disabled')
      } else if (this.isDelivery) {
        ret = this.$t('resto.checkout_review_timeslots_delivery')
      }

      return ret
    },

    value() {
      let ret = null
      if(!this.$basil.isNil(this.orderTimeslot)) {
        let ts = this.$basil.get(this.orderTimeslot, 'timestamp')
        let tz = this.$basil.get(this.orderTimeslot, 'timezone.offset')
        let v = new Date(((ts + tz) + (new Date().getTimezoneOffset() * 60)) * 1000)

        ret = this.$date(v)
      }
      return ret
    },

    valueTime() {
      let ret = null
      if(!this.$basil.isNil(this.orderTimeslot)) {
        let ts = this.$basil.get(this.orderTimeslot, 'timestamp')
        let tz = this.$basil.get(this.orderTimeslot, 'timezone.offset')
        let v = new Date(((ts + tz) + (new Date().getTimezoneOffset() * 60)) * 1000)

        let hours = v.getHours()
        if(hours < 10) {
          hours = '0' + hours
        }

        let minutes = v.getMinutes()
        if(minutes < 10) {
          minutes = '0' + minutes
        }

        ret = hours + ':' + minutes
      }
      return ret
    },

    weekDays() {
      return [
        this.$t('resto.day_sunday'),
        this.$t('resto.day_monday'),
        this.$t('resto.day_tuesday'),
        this.$t('resto.day_wednesday'),
        this.$t('resto.day_thursday'),
        this.$t('resto.day_friday'),
        this.$t('resto.day_saturday'),
      ]
    },

    openingHours() {
      return !this.$basil.isNil(this.service) ?
        this.$basil.get(this.service, 'openingHours', null) : null
    }
  },

  methods: {
    onCancel() {
      if(this.state.valid){
        this.$store.commit('setErrors', { card: 'timeslot', values: [] })
        this.$store.commit('setActive', { card: 'timeslot', value: false })
      }
    },

    onChangeDate(date) {
      this.timeslotValue = null
      // this.timeslot = null
      this.date = date
      this.reset()
    },

    onChangeFlpos(value, reset = false) {
      this.$store.commit('setLoading', { card: 'timeslot', value: true })
      this.$store.commit('setValid', { card: 'timeslot', value: false })
      this.$store.commit('setErrors', { card: 'timeslot', values: [] })

      return new Promise((resolve, reject) => {
        this.$checkout.setFlpos({flpos: value.value})
          .then(() => {
            this.date = null
            resolve()
          })
          .catch((e) => reject(e))
          .finally(() => this.reset())
      })
    },

    onChangeTimeslot(v) {
      this.$store.commit('setErrors', { card: 'timeslot', values: [] })
      this.almostAtCapacity = this.$basil.get(this.timeslot, 'value.almost_at_capacity', false)
    },

    onConfirm() {
      this.error500 = false
      this.errorStatus = null

      if(!this.hasFlpos) {
        this.$store.commit('setErrors', { card: 'timeslot', values: { flpos: [this.$t('resto.flpos_is_required')] } })
        return
      }

      this.$store.commit('setLoading', { card: 'timeslot', value: true })
      this.$store.commit('setErrors', { card: 'timeslot', values: [] })

      if(!this.$basil.isNil(this.timeslotValue)) {
        let timeslotValue = JSON.parse(JSON.stringify(this.timeslotValue))
        let timeslotAsap = false

        if(String(timeslotValue).includes(':asap')) {
          timeslotAsap = true
          timeslotValue = String(timeslotValue).replace(':asap', '')
        }

        try {
          timeslotValue = parseInt(timeslotValue, 10)
        } catch(e) {}

        this.$checkout.timeslots.setTimeslot({ time: timeslotValue || null, asap: timeslotAsap === true ? true : false })
          .then(() => {
            this.$store.commit('setValid', { card: 'timeslot', value: true })
            this.$store.commit('setValid', { card: 'delivery', value: false })
            this.$store.commit('setActive', { card: 'timeslot', value: false })
          })
          .catch((error) => {
            $console.error(error)

            if(this.$basil.get(error, 'response.status', 0) >= 500) {
              this.error500 = true
              this.errorStatus = this.$basil.get(error, 'response.status')
            }

            this.$store.commit('setErrors', { card: 'timeslot', values: { timeslot: [this.$t('resto.field_is_required')] }})
          })
          .finally(() => this.$store.commit('setLoading', { card: 'timeslot', value: false }))
      } else {
        $console.error({ timeslot: [this.$t('resto.field_is_required')] })
        this.$store.commit('setErrors', { card: 'timeslot', values: { timeslot: [this.$t('resto.field_is_required')] }});
        this.$store.commit('setLoading', { card: 'timeslot', value: false })
      }
    },

    onEdit() {
      this.$store.commit('setActive', { card: 'timeslot', value: true })
    },

    reset() {
      this.error500 = false
      this.errorStatus = null

      if (this.$basil.isNil(this.order)) {
        return
      }

      // Confirm we have at least one active flpos
      let flpos = this.$basil.get(this.shop, 'flposes', []);
      flpos = flpos.filter(s => s.services.includes(this.serviceName))
      if (flpos.length === 0){
        this.$router.push({ name: 'sayl-front-catalog.catalog', params: {service: this.serviceName} })
      }

      this.timeslotValue = null

      this.$store.commit('setActive', { card: 'timeslot', value: true })
      this.$store.commit('setLoading', { card: 'timeslot', value: true })
      this.$store.commit('setErrors', { card: 'timeslot', values: this.$basil.get(this.termState, 'errors', []) })
      this.$store.commit('setErrors', { card: 'term', values: {} })

      let prom = Promise.resolve.bind(Promise, {})

      if(this.hasFlpos && this.$basil.get(this.order, 'fulfillment_location_id') != this.$basil.get(this.selectedFlpos, 'value.id')) {
        prom = this.$checkout.setFlpos.bind(this.$checkout, { flpos: this.selectedFlpos.value })
      }

      if((!this.$basil.isNil(this.flposesList) && this.flposesList.length > 0) && (!this.hasMultipleFlposes || (!this.isPickup && !this.isDelivery))) {
        prom = this.$checkout.setFlpos.bind(this.$checkout, { flpos: this.flposesList[0].value })
      }

      prom.call()
        .then(() => {
          if (!this.timeslotsDisabled) {
            return this.$checkout.timeslots.getDays({ shopId: this.shop.id, service: this.service.name, flposId: this.$basil.get(this.selectedFlpos, 'value.id') })
          } else {
            this.$store.commit('setValid', { card: 'timeslot', value: true })
            this.$store.commit('setValid', { card: 'delivery', value: false })
            this.$store.commit('setActive', { card: 'timeslot', value: false })
            return Promise.resolve()
          }
        })
        .then(() => {
          if(this.date == null && !this.$basil.isNil(this.dates) && this.dates.length > 0) {
            this.date = this.dates[0].value
            this.key++
          }

          if(!this.$basil.isNil(this.date)) {
            return this.$checkout.timeslots.fetch({ time: this.date })
          }
          return Promise.resolve()
        })
        .then(() => {
          if(this.forceAsap && this._timeslots) {
            let t = this._timeslots.find((ts) => ts.has_capacity)
            if(t) {
              this.timeslotValue = t.value + ':asap'
              if(!this.hasMultipleFlposes && this.timeslotValue) {
                setTimeout(() => {
                  this.onConfirm()
                }, 10)
              }
            }
          }
        })
        .catch((error) => {
          $console.error(error)

          if(this.$basil.get(error, 'response.status', 0) >= 500) {
            this.error500 = true
            this.errorStatus = this.$basil.get(error, 'response.status')
          }

          this.$store.commit('setErrors', { card: 'timeslot', values: this.$basil.get(error, 'response.data.messages', []) })
        })
        .finally(() => this.$store.commit('setLoading', { card: 'timeslot', value: false }))
    },
  },

  mounted() {
    !this.checkoutLoading && this.reset()
  }
}
</script>

<template>
  <card
    class="checkout-teaser-loyalty"
    v-bind="state"
    v-show="loyaltyReward > 0">

    <!-- header -->
    <template v-slot:header>
      <div class="checkout-teaser__header">
        <div class="checkout-teaser__row">
          <ui-icon
            class="checkout-card__illustration"
            glyph="gift"
          />

          <actions-button
            class="checkout-teaser__cta -mobile"
            :size="$pepper.Size.S"
            @click="showLoyaltyDescription = !showLoyaltyDescription"
          >{{ $t('resto.show_loyalty_description') }}</actions-button>
        </div>

        <div class="checkout-teaser__intro">
          <h2 class="checkout-card__title -has-action">
            <span
              class="checkout-teaser__value"
              v-html="title"></span>

            <actions-button
              class="checkout-teaser__cta -desktop"
              :size="$pepper.Size.S"
              @click="showLoyaltyDescription = !showLoyaltyDescription"
            >{{ $t('resto.show_loyalty_description') }}</actions-button>
          </h2>

          <div class="checkout-card__description" v-if="!isLogged">{{ description }}</div>
        </div>
      </div>
    </template>

    <template v-slot:subtitle>
      <article
        v-if="showLoyaltyDescription"
        class="checkout-teaser-loyalty__description">
        <p v-if="$basil.isNil(placeholderCheckout)">
          {{ $t('resto.loyalty_description', {
            minRedemption,
            pointsPerMoney,
            moneyPerPoints,
            welcomeReward,
            pointName,
            pointSymbol,
            currency
          }) }}
        </p>

        <p
          v-else
          v-html="placeholderCheckout"
        ></p>
      </article>
    </template>

    <template
      v-if="!isLogged"
      v-slot:read>
        <!-- Create an account -->
        <article class="checkout-teaser-loyalty__article">
          <!-- Create an account:title -->
          <div class="checkout-teaser-loyalty__title">{{ $t('resto.user_login_subscribe') }}</div>

          <!-- Create an account:description -->
          <div
            class="checkout-teaser-loyalty__description"
            v-html="createAccountDescription"
          ></div>

          <!-- Create an account:action -->
          <actions-button
            class="checkout-teaser__cta -m-fill"
            @click="onSignUpClick"
            :size="$pepper.Size.S"
          >{{ $t('resto.action_signup') }}</actions-button>
        </article>

        <!-- Login -->
        <article class="checkout-teaser-loyalty__article">
          <!-- Login:title -->
          <div class="checkout-teaser-loyalty__title">{{ $t('resto.user_already_have_account') }}</div>

          <!-- Login:description -->
          <div class="checkout-teaser-loyalty__description">{{ $t('resto.user_loyalty_sign_to_collect') }}</div>

          <!-- Login:action -->
          <actions-button
            class="checkout-teaser__cta -m-fill"
            @click="onSignInClick"
            :size="$pepper.Size.S"
            >{{ $t('resto.action_signin') }}</actions-button>
        </article>
    </template>
  </card>
</template>

<script>
import { mapState } from 'vuex'
import Card from '../card'

export default {
  name: 'CheckoutReviewTeaserLoyaltyCard',

  inject: [
    '$checkout',
    '$core',
    '$embed',
    '$loyalty',
    '$user'
  ],

  components: {
    Card,
  },

  extends: Card,

  data() {
    return {
      showLoyaltyDescription: false
    }
  },

  computed: {
    ...mapState({
      i18n: state => state.bootstrap.i18n,
      order: state => state['sayl-front.checkout'].order,
      state: state => state['checkout'].teaser.loyalty,
    }),

    balance() {
      let ret = this.$basil.get(this.user, 'balance', null)
      return ret
    },

    createAccountDescription() {
      return this.hasWelcomeReward ?
        `${this.$t('resto.user_loyalty_sign_in_descr')} <output>${this.welcomeReward} ${this.loyaltyPointsName}</output>` :
        this.$t('resto.user_loyalty_sign_up_to_collect')
    },

    currency() {
      return this.$basil.get(this.shop, 'currency.symbol', '€');
    },

    description() {
      return this.$t('resto.user_loyalty_login_to_collect')
    },

    hasWelcomeReward() {
      return this.welcomeReward > 0
    },

    isLogged() {
      return this.$basil.get(this.user, 'isLogged')
    },

    loyalty() {
      return this.$basil.get(this.$loyalty, 'program', null)
    },

    loyaltyPointsName() {
      return this.$basil.get(this.loyalty, 'points.name', null)
    },

    loyaltyName() {
      return this.$basil.get(this.loyalty, 'name', null)
    },

    loyaltyReward() {
      let ret = 0;
      $console.info(this.loyalty)
      ret = this.loyalty.rules.convertMoneyToPoints(this.orderPrice);

      return this._parseNum(ret)
    },

    minRedemption() {
      return this._parseNum(this.$basil.get(this.loyalty, 'rules.minRedemptionThreshold', 0));
    },

    moneyPerPoints() {
      return this._parseNum(this.loyalty.rules.convertPointsToMoney(1));
    },

    orderPrice() {
      return this.$basil.get(this.order, 'price_total', null)
    },

    placeholders() {
      return this.$basil.get(this.loyalty, 'placeholders', []) || [];
    },

    placeholderCheckout() {
      let ret = this.placeholders.find(p => p.key === 'checkout');

      return this.$basil.isNil(ret) ?
        null :
        this.$basil.get(ret, `content.${this.userLang}`, this.$basil.get(ret, 'content.all'));
    },

    pointName() {
      return this.$basil.get(this.loyalty, 'points.name', '');
    },

    pointSymbol() {
      return this.$basil.get(this.loyalty, 'points.symbol', '');
    },

    pointsPerMoney() {
      return this._parseNum(this.loyalty.rules.convertMoneyToPoints(1));
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    title() {
      return this.$t('resto.user_loyalty_named_teaser_title', { points: parseInt(this.loyaltyReward, 10), namepoint: this.loyaltyPointsName, name: this.loyaltyName })
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLang() {
      return this.$basil.get(this.user, 'lang', this.i18n.locale.lang.toString())
    },

    welcomeReward() {
      return this._parseNum(this.$basil.get(this.loyalty, 'rules.welcomeReward', 0));
    },
  },

  methods: {
    onSignUpClick() {
      this.$bus.$emit('sign-up');
    },

    onSignInClick() {
      this.$bus.$emit('sign-in');
    },

    _parseNum(value) {
      let ret = 0;
      let integ = parseInt(value, 10);
      let flo = parseFloat(value, 10);
      let rest = flo - integ;
      if(rest > 0  && rest < 1) {
        ret = flo.toFixed(2);
      } else {
        ret = flo.toFixed();
      }
      return ret
    }
  },
}
</script>

<template>
  <card
    class="checkout-voucher"
    :class="{ '-mb': !state.active && !hasTrigger }"
    :key="key"
    @cancel="onCancel"
    @confirm="onConfirm"
    v-bind="state">
    <template
      v-slot:header
      v-if="!hasTrigger">
      <h2 class="checkout-card__title">{{ title }}</h2>

      <sayl-conn3ct-auth-button
        class="checkout-voucher__button"
        :claims="JSON.stringify(claims)"
        :loading="isLoading"
        @e_token="scanWallet"
        @e_click="connectWallet"
        @e_wallet_closed="onWalletClosed"
      />
    </template>

    <template v-slot:read>
      <article
        class="checkout-card__article checkout-voucher__voucher"
        v-if="hasTrigger">
        <div class="checkout-voucher__row">
          <div class="checkout-card__illustration">
            <ui-icon :glyph="trigger.type === 'discount_perc' ? 'percent' : 'license'"></ui-icon>
          </div>

          <div class="checkout-card__inner checkout-voucher__code -desktop">
            <span class="checkout-card__label checkout-voucher__code-name">{{ getTranslation(trigger.name) }}</span>

            <data-badge
              class="checkout-voucher__code-discount"
              :value="discount"
              v-if="trigger.type === 'discount_perc'"
            />
          </div>

          <actions-button
            class="checkout-voucher__cta"
            :size="$pepper.Size.S"
            @click="disconnectWallet"
            v-if="hasTrigger"
          >{{ $t('resto.checkout_review_sayl_wallet_clear') }}</actions-button>
        </div>

        <div class="checkout-voucher__row -mobile">
          <div class="checkout-card__inner checkout-voucher__code">
            <span class="checkout-card__label checkout-voucher__code-name">{{ getTranslation(trigger.name) }}</span>

            <data-badge
              class="checkout-voucher__code-discount"
              :value="discount"
              v-if="trigger.type === 'discount_perc'"
            />
          </div>
        </div>
      </article>

      <article
        class="checkout-card__article"
        v-if="noPerks">
        <forms-label :intent="$pepper.Intent.DANGER">{{ $t('resto.checkout_review_sayl_wallet_no_triggers') }}</forms-label>
      </article>
    </template>
  </card>
</template>

<script>
import { mapState } from 'vuex'

import Card from './card'

import MixinCurrency from '@/helpers/currency'
import MixinTranslations from '@/helpers/translations'

export default {
  name: 'CheckoutReviewSaylWallet',

  mixins: [
    MixinCurrency,
    MixinTranslations,
  ],

  inject: [
    '$checkout'
  ],

  components: {
    Card,
  },

  extends: Card,

  data() {
    return {
      claims: ['address', 'perks'],
      connectionTried: false,
      key: 1,
      manualWalletClose: false,
      walletErrors: [],
    }
  },

  computed: {
    ...mapState({
      state: state => state.checkout.saylWallet,
      wallet: state => state['sayl-front.checkout'].wallet,
      order: state => state['sayl-front.checkout'].order,
      states: state => state.bootstrap.states,
    }),

    discount() {
      return this.toCurrency(this.$basil.get(this.order, 'web3_trigger.discount') * - 1)
    },

    hasTrigger() {
      return !this.$basil.isNil(this.trigger)
    },

    hasWallet() {
      return !this.$basil.isNil(this.wallet)
    },

    isLoading() {
      return [this.states.APPLICATION_INIT, this.states.APPLICATION_AUTH, this.states.APPLICATION_MODULES].includes(this.status)
    },

    noPerks() {
      const errors = this.$basil.get(this.walletErrors, 'address', [])
      return errors.find(error => error === 'no_perks_linked')
    },

    title() {
      return this.$t('resto.checkout_review_sayl_wallet_title')
    },

    trigger() {
      return this.$basil.get(this.order, 'web3_trigger')
    },

    saylWallet() {
      return this.$basil.get(window, 'conn3ct')
    }
  },

  methods: {
    disconnectWallet() {
      this.onEdit()

      this.connectionTried = false
      this.$store.commit('setLoading', { card: 'saylWallet', value: true })
      this.$store.commit('sayl-front.checkout/setWallet', null)

      this.$checkout.wallet.clear()
        .catch((e) => $console.error(e))
        .finally(() => {
          this.key++
          this.$store.commit('setLoading', { card: 'saylWallet', value: false })
        })
    },

    connectWallet() {
      this.$store.commit('setLoading', { card: 'saylWallet', value: true })
    },

    onCancel() {
      this.code = ''
      this.$store.commit('setErrors', { card: 'saylWallet', values: [] })
      this.$store.commit('setActive', { card: 'saylWallet', value: false })
      this.key++
    },

    onConfirm() {
      if(this.$basil.isNil(this.wallet)) {
        this.$notification({
          title: this.$t('resto.checkout_review_no_sayl_wallet_title'),
          message: this.$t('resto.checkout_review_no_sayl_wallet_description'),
          type: 'error'
        })

        return;
      }

      this.$store.commit('setLoading', { card: 'saylWallet', value: true })
      this.$store.commit('setErrors', { card: 'saylWallet', values: [] })

      this.$checkout.wallet.add({ wallet: this.wallet })
        .then(() => {
          this.connectionTried = true;
          this.$store.commit('setLoading', { card: 'paymentMethod', value: true })
          return this.$checkout.payment.find({})
        })
        .then(() => {
          this.$store.commit('setActive', { card: 'saylWallet', value: false })
          this.$store.commit('setValid', { card: 'saylWallet', value: true })
        })
        .catch((error) => {
          $console.error(error)
          if (error.response){
            let errors = this.$basil.get(error, 'response.data.errors', {})
            let ks = Object.keys(errors);

            errors = ks.map(k => {
              return this.$t(`resto.checkout-saylWallet-${errors[k][0]}`)
            })
            this.$store.commit('setValid', { card: 'saylWallet', value: true })
            this.$store.commit('setErrors', { card: 'saylWallet', values: errors })
            return;
          }
        })
        .finally(() => {
          this.key++
          this.$store.commit('setLoading', { card: 'saylWallet', value: false })
          this.$store.commit('setLoading', { card: 'paymentMethod', value: false })
        })
    },

    onEdit() {
      this.$store.commit('setErrors', { card: 'saylWallet', values: [] })
    },

    scanWallet(data) {
      const token = this.$basil.get(data, 'token', this.$basil.get(data, 'detail.token'))

      this.$store.commit('setLoading', { card: 'saylWallet', value: true })
      this.$store.commit('sayl-front.checkout/setWallet', token)
      this.manualWalletClose = true

      this.saylWallet.disconnect()
        .then(() => this.saylWallet.close())
        .then(() => this.$checkout.wallet.add({ wallet: this.wallet }))
        .then(() => {
          this.$store.commit('setLoading', { card: 'paymentMethod', value: true })
          return this.$checkout.payment.find({})
        })
        .then(() => {
          this.$store.commit('setActive', { card: 'saylWallet', value: false })
          this.$store.commit('setValid', { card: 'saylWallet', value: true })
        })
        .catch((e) => {
          $console.error(e)

          let status = this.$basil.get(e, 'response.status', e.status)

          if(!this.$basil.isNil(status)) {
            switch(status) {
              case 404:
              case 422:
                let errors = this.$basil.get(e, '_items')
                this.$store.commit('setErrors', { card: 'saylWallet', values: errors })
                this.walletErrors = errors
                this.$store.commit('setValid', { card: 'saylWallet', value: true })
              break

              case 500:
                this.$router.replace({ name: 'server-error', params: { entity } })
                break
            }
          }
        })
        .finally(() => {
          this.key++
          this.$store.commit('setLoading', { card: 'saylWallet', value: false })
          this.$store.commit('setLoading', { card: 'paymentMethod', value: false })
        })
    },

    onWalletClosed() {
      if(this.manualWalletClose) {
        return
      }

      this.$store.commit('setLoading', { card: 'saylWallet', value: false })
    },

    reset() {
      if(this.$basil.isNil(this.order.web3_address) && this.$basil.isNil(this.order.web3_trigger)) {
        return;
      }

      this.$store.commit('sayl-front.checkout/setWallet', this.order.web3_address)
    },
  },

  mounted() {
    this.reset()
  }
}
</script>

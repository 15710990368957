<template>
  <!-- Logged -->
  <div :class="classes">
    <div class="ui-user__body">
      <div class="ui-user__icon">
        <ui-icon glyph="user"></ui-icon>
      </div>

      <div class="ui-user__inner">
        <div class="ui-user__label">
          {{ userName }}

          <data-tag
            class="ui-user__tier"
            :appearance="$pepper.Appearance.PRIMARY"
            :size="$pepper.Size.S"
            v-if="userTier"
          >{{ userTier }}</data-tag>
        </div>

        <div
          class="ui-user__sublabel"
          v-if="userEmail"
        >{{ userEmail }}</div>

        <div
          class="ui-user__sublabel"
          v-if="userPhone"
        >{{ userPhone }}</div>

        <div
          class="ui-user__sublabel -tier"
          v-if="userTier && false"
        >{{ userTier }}</div>

        <actions-button
          appearance="subtle"
          class="ui-user__link"
          @click="onLogout"
          :size="$pepper.Size.S"
          v-if="isLogged"
        >{{ $t('resto.logout') }}</actions-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuthUserLogged',

  inject: [
    '$core',
    '$user',
    '$embed',
    '$loyalty',
  ],

  computed: {
    ...mapState({
      order: state => state['sayl-front.checkout'].order,
      shops: state => state.bootstrap.data.extensions.oat,
    }),

    classes() {
      return {
        'ui-user': true,
        'flow': true,
      }
    },

    isLogged() {
      return this.$basil.get(this.user, 'isLogged')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userEmail() {
      let ret = this.$basil.get(this.user, 'email')
      return ret
    },

    userName() {
      let ret = this.$basil.get(this.user, 'firstname');

      if(!this.$basil.isNil(this.user.lastname) && !this.$basil.isEmpty(this.user.lastname)) {
        ret += ` ${this.$basil.get(this.user, 'lastname').toUpperCase()}`
      }
      return ret
    },

    userPhone() {
      let ret = this.$basil.get(this.user, 'phone')
      return ret
    },

    userTier() {
      if(this.$basil.isNil(this.tier)) {
        return null
      }

      return this.$basil.get(this.tier, 'name')
      return this.$t('resto.user_card_loyalty_tier') + ' ' + this.$basil.get(this.tier, 'name')
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    isMultiShopsEnabled() {
      let shop = this.shops?.find(s => s.shop_id === this.embed.shopId)

      return shop?.is_multi_shops_enabled ?? false
    },

    tier() {
      let tiers = this.$basil.get(this.$loyalty, 'program.tiers', [])
      let userTier = this.$basil.get(this.$user, 'user.loyaltyTierId', null)

      if(tiers && tiers.length > 0 && userTier) {
        let ret = tiers.find(t => t.id === userTier)
        if(ret) {
          return ret
        }
      }

      return null
    },

    tierName() {
      return !this.$basil.isNil(this.tier) ? this.$basil.get(this.tier, 'name') : null
    }
  },

  methods: {
    onLogout() {
      let logout = () => {
        this.$store.commit('setLoading', { card: 'auth', value: true })
        this.$user.reset()
          .then(() => this.$core.initUser({}))
          .then(() => this.$core.initCart({
            fresh: true,
            service: this.order.service,
            enrich: this.isMultiShopsEnabled ? 1 : 0
          }))
          .then(() => {
            this.$store.commit('setValid', { card: 'auth', value: false })
            this.$router.push({ name: 'sayl-front-catalog.catalog' }).catch(() => {})
          })
          .catch((e) => $console.error(e))
          .finally(() => this.$store.commit('setLoading', { card: 'auth', value: true }))
      }

      this.$confirm({
        title: this.$t('resto.logout_confirmation_title'),
        description: this.$t('resto.logout_confirmation_description'),
        onPrimary: logout
      })
    }
  }
}
</script>

<template>
  <main :class="classes">
    <!-- Review -->
    <div
      class="container">
      <actions-button
        appearance="subtle"
        icon-pre="arrow-left"
        :href="backRoute"
      >{{ $t('resto.back_button') }}</actions-button>

      <!-- Processing -->
      <div
        class="container__loader"
        v-if="loading">
        <ui-loader size="l" />
      </div>

      <div
        class="-cols -m-reverse"
        v-if="!loading">
        <div class="col">
          <!-- Form -->
          <transition-group
            class="view-checkout-review__form flow"
            name="review-cards"
            tag="div"
            v-if="!isEditing && !isLoading && !invalid">

            <component
              v-for="step in displayedCards"
              :key="step.name"
              :is="step.component"
              v-bind="step.attrs"
              :disabled="isEditing"
            />
          </transition-group>

          <!-- Processing -->
          <div
            class="container__loader"
            v-if="isLoading">
            <ui-loader size="l" />
          </div>

          <div class="" v-if="invalid">
            <notice-banner
              :intent="$pepper.Intent.DANGER"
              :title="$t('resto.cart_some_shops_closed_title')"
              :description="errorMsg"
            />
          </div>
        </div>

        <!-- Cart -->
        <section class="col view-checkout-review__cart">
          <div class="checkout-cart">
            <div class="checkout-cart__header">
              <h1
                class="view-checkout-review__title"
                v-if="isEatin"
              >{{ $t('resto.table_number') }}{{ tableNumber }}</h1>

              <div class="view-checkout-review__basket-title">
                <h2 class="view-checkout-review__subtitle">{{ $t('resto.checkout_review_cart_subtitle') }}</h2>

                <actions-button
                  class="view-checkout-review__edit"
                  :appearance="$pepper.Appearance.PRIMARY"
                  :size="$pepper.Size.S"
                  @click="onEditClick"
                  v-if="!isEditing"
                >{{ $t('resto.checkout_cart_edit_action') }}</actions-button>
              </div>
            </div>

            <component
              :is="isEditing || invalid ? 'CatalogCart' : 'CheckoutCart'"
              :class="!isEditing ? '-order' : null"
              :editable="isEditing"
              :shopable="isMultiShop"
              :key="key"
            />

            <actions-button
              :appearance="$pepper.Appearance.PRIMARY"
              class="view-checkout-review__action"
              :disabled="$cart.changing"
              :size="$pepper.Size.S"
              @click="onEndClick"
              v-if="isEditing"
            >{{ $t('resto.checkout_cart_checkout_action') }}</actions-button>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import CatalogCart from '@/modules/catalog/components/cart/cart'
import CheckoutCart from '@/modules/checkout/components/cart/cart'

import MixinMultishop from '@/helpers/multishop'
import MixinOrderable from '@/helpers/orderable'
import MixinServices from '../mixins/services'
import MixinSteps from '../mixins/steps'

export default {
  name: 'CheckoutReview',

  components: {
    CatalogCart,
    CheckoutCart,
  },

  inject: [
    '$catalog',
    '$cart',
    '$core',
    '$checkout',
    '$embed',
    '$localStorage',
    '$user',
  ],

  mixins: [
    MixinMultishop,
    MixinOrderable,
    MixinServices,
    MixinSteps,
  ],

  data() {
    return {
      isEditing: false,
      isLoading: false,
      key: 1,

      invalid: false,
      errorMsg: null
    }
  },

  computed: {
    ...mapState({
      service: state => state['sayl-front.service'].service,
      order: state => state['sayl-front.checkout'].order,
      cart: state => state['sayl-front.cart'].cart,
      loading: state => state['sayl-front.checkout'].loading,
      table: state => state['sayl-front.service-eatin'].table,
      timeslot: state => state['sayl-front.checkout'].timeslot,
      paymentMethod: state => state['sayl-front.checkout'].paymentMethod,
      shops: state => state.bootstrap.data.extensions.oat,
    }),

    ...mapGetters({
      cardActives: 'actives',
    }),

    backRoute() {
      return {
        name: this.isMultiShop ? 'sayl-front-catalog.shops' : 'sayl-front-catalog.catalog',
        params: {
          embed: this.$route.params.embed,
          service: this.$localStorage.service
        }
      }
    },

    classes() {
      return {
        'view-checkout-review__body': true,
        '-is-loading': this.loading
      }
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    isOpen() {
      return this.$basil.get(this.service, 'isOpen', false)
    },

    tableNumber() {
      let ret = this.table ? this.table.name : null
      return ret;
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    isMultiShopsEnabled() {
      let shop = this.shops?.find(s => s.shop_id === this.embed.shopId)

      return shop?.is_multi_shops_enabled ?? false
    },
  },

  methods: {
    onEditClick() {
      this.isEditing = true
      this.key++
      $console.groupEnd('checkout.init')
    },

    onEndClick() {
      this.isEditing = false
      if(this.$basil.get(this.cart, 'items', []).length === 0) {
        return this.$router.replace({
          name: 'sayl-front-catalog.catalog',
          params: {
            embed: this.$basil.get(this.embed, 'id'),
            service: this.$basil.get(this.service, 'name')
          }
        })
        .catch(() => {})
      }

      this.reset()
    },

    reset() {
      // this.$store.commit('sayl-front.checkout/setLoading', true)
      this.$store.dispatch('reset')
      this.isLoading = true
      // Use the user information if set in the latest 2h
      let user = this.$localStorage.user

      if(!this.$basil.isNil(user)) {
        let now = new Date()
        let date = new Date(Date.parse(user.date))
        date.setHours(date.getHours() + 2)

        if(date.getTime() > now.getTime()) {
          $console.info('Checkout: User retrieved from localStorage')
          this.user.firstname = user.firstname
          this.user.lastname = user.lastname
          this.user.email = user.email
          this.user.phone = user.phone
        }
        else {
          this.$localStorage.user = null
          $console.info('Checkout: User removed: Older than 2h')
        }
      }

      this.$checkout.init({ cart: this.cart })
        .then(() => this.$checkout.getFees())
        .then(() => this.$checkout.payment.reset())
        .catch((err) => {
          $console.error(err)
          if(err.status === 422) {
            this.invalid = true
            this.errorMsg = this.$t(`resto.${this.$basil.get(err, '_items[0].error', 'checkout_error_422')}`)
          }

          this.$notification({
            title: this.$t('resto.error_confirm_title'),
            message: this.$t('resto.error_confirm_description'),
            type: 'error'
          })
          this.key++
        })
        .finally(() => {
          this.$store.commit('sayl-front.checkout/setLoading', false)
          this.isLoading = false
        })
    },
  },

  mounted() {
    // Shop not opened
    // Or no product in the cart
    let proms = [Promise.resolve.bind(Promise)]
    if(this.$basil.isNil(this.cart)) {
      proms = [
        this.$core.initCatalog.bind(this.$core, {  }),
        this.$core.initCart.bind(this.$core, {
          service: this.$localStorage.service,
          enrich: this.isMultiShop ? 1 : 0
        }),
      ]
    }

    this.$basil.sequence(proms)
      .then(() => {
        if(!this.isEnabled || this.$basil.get(this.cart, 'items', []).length === 0) {
          this.$router.replace(this.backRoute).catch(() => {})
          return 
        }

        this.$bus.$emit('injekt.sp.checkout')
        this.reset()
        this.key++
      })
      .catch((e) => $console.error(e))
  },

  created() {
    this.$bus.$on('change-lang', () => {
      this.$checkout.resetUser()
        .then(() => {
          this.$bus.$emit('change-lang-payments')
          this.$bus.$emit('change-lang-delivery')
        })
        .catch((e) => $console.error(e))
    })
  },

  beforeMount() {
    this.$store.commit('sayl-front.checkout/setLoading', true)
  },

  beforeDestroy() {
    this.$bus.$off('change-lang')
  }
}
</script>

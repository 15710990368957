<template>
  <card
    class="checkout-loyalty"
    v-bind="state"
    @cancel="onCancel"
    @confirm="onConfirm">
    <template v-slot:header>
      <div class="checkout-loyalty__row">
        <h2 class="checkout-card__title">{{ title }}</h2>

        <actions-button
          class="shop__cta"
          :size="$pepper.Size.S"
          @click="onEdit"
          v-if="!active && hasRedemption"
        >{{$t('resto.change')}}</actions-button>
      </div>

      <div class="checkout-loyalty__row">
        <div
          class="checkout-card__description"
          v-html="description"
        ></div>
      </div>
    </template>

    <!-- Read -->
    <template v-slot:read>
      <!-- Loyalty -->
      <article
        class="checkout-card__article checkout-loyalty__value"
        v-if="hasRedemption">
        <div class="checkout-card__illustration">
          <ui-icon glyph="gift" />
        </div>

        <div class="checkout-card__inner checkout-loyalty__value">
          <span class="checkout-card__label checkout-loyalty__value-label">{{ $t('resto.checkout_card_loyalty_used_reward_money') }}</span>

          <data-badge
            class="checkout-loyalty__money"
            :value="$n(loyaltyMoney, 'currency')"
          />
        </div>
      </article>

      <article
        class="checkout-card__article checkout-loyalty__reduction"
        v-if="!hasRedemption">
        <span class="checkout-card__label checkout-loyalty__value-label">{{ $t('resto.checkout_card_loyalty_redemption_threshold_not_met', { amount: thresholdRemainingAmount, points: pointsSymbol }) }}</span>
      </article>
    </template>

    <!-- Edit -->
    <template v-slot:edit>
      <div class="checkout-loyalty__article">
        <!-- Money -->
        <forms-input
          :appearance="$pepper.Appearance.SUBTLE"
          class="checkout-loyalty__write-value"
          icon-pre="award"
          ref="money"
          :max="maxMoney"
          min="0"
          step="1"
          type="number"
          @keyup="onMoneyKeyup"
          v-model="money">
          <template>{{ $t('resto.checkout_card_loyalty_reduction') }}</template>
          <template v-slot:suffix>{{ currencyCode }}</template>
        </forms-input>
      </div>

      <div
        class="checkout-loyalty__article"
        v-if="!isMax">
        <div class="checkout-loyalty__hint">{{ $t('resto.checkout_card_loyalty_hint_amount_available', { remainingPoints, remainingMoney }) }}</div>
      </div>
    </template>

    <template #footer>
      <actions-button
        appearance="subtle"
        @click="onCancel"
        :disabled="!valid || !isApplied"
        v-if="hasRedemption"
      >{{ $t('resto.cancel') }}</actions-button>

      <actions-button
        appearance="primary"
        :loading="loading"
        @click="onConfirm"
        v-if="hasRedemption"
      >{{ $t('resto.confirm_loyalty') }}</actions-button>
    </template>
  </card>
</template>

<script>
import { mapState } from 'vuex'
import Card from './card'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'CheckoutReviewLoyaltyCard',

  inject: [
    '$checkout',
    '$core',
    '$loyalty',
    '$user'
  ],

  components: {
    Card
  },

  extends: Card,

  mixins: [MixinCurrency],

  data(){
    return {
      points: 0,
      money: 0,
      key: 1,
    }
  },

  computed: {
    ...mapState({
      state: state => state['checkout'].loyalty,
      order: state => state['sayl-front.checkout'].order,
    }),

    balance() {
      return this.$basil.get(this.user, 'balance', null)
    },

    description() {
      let points = this.$basil.get(this.balance, 'points', 0)
      points = `${!this.$basil.isNil(points) ? points.toFixed(2) : points} ${this.pointsSymbol}`

      if(!this.hasRedemption) {
        return this.$t('resto.checkout_review_loyalty_description', { points })
      } else {
        let money = this.maxMoney
        money = !this.$basil.isNil(money) ? this.$n(money, 'currency') : money
        return this.$t('resto.checkout_review_loyalty_description_with_money', { points, money })
      }
    },

    hasRedemption() {
      return this.balance.points > this.minRedemptionThreshold
    },

    isApplied() {
      return !this.$basil.isNil(this.$basil.get(this.order, 'credits', null))
    },

    isMax() {
      return this.money >= this.maxMoney // && this.maxPoints <= this.points
    },

    loyalty() {
      return this.$basil.get(this.$loyalty, 'program', null)
    },

    loyaltyMoney() {
      return this.$basil.get(this.order, 'credits.money', 0);
    },

    pointsSymbol() {
      let ret = this.$basil.get(this.loyalty, 'points.symbol', null)
      return ret
    },

    pointsName() {
      let ret = this.$basil.get(this.loyalty, 'points.name', null)
      return ret
    },

    maxMoney() {
      let ret = 0;

      if(this.orderPrice >= this.balance.money) {
        ret = this.balance.money
      } else {
        ret = this.orderPrice
      }

      return parseFloat(ret).toFixed(2)
    },

    maxPoints() {
      let ret = 0;
      let p = this.balance.convertMoneyToPoints(this.orderPrice)

      if(p >= this.balance.points) {
        ret = this.balance.points
      } else {
        ret = p
      }

      return Math.ceil(parseFloat(ret).toFixed(2))
    },

    minRedemptionThreshold() {
      return this.$basil.get(this.loyalty, 'rules.minRedemptionThreshold', 0)
    },

    orderPrice() {
      let values = [
        this.$basil.get(this.order, 'price_total', 0),
        this.$basil.get(this.order, 'credits.money', 0)
      ]

      return values.reduce((acc, cur) => acc+=cur, 0)
    },

    remainingMoney() {
      let ret = 0

      if(!this.isMax && this.maxMoney >= this.orderPrice) {
        ret = this.orderPrice - this.money
      } else if(!this.isMax && this.maxMoney < this.orderPrice) {
        ret = this.maxMoney - this.money
      }

      return ret > 0 ? this.$n(ret, 'currency') : 0
    },

    remainingPoints() {
      let money = 0
      let ret = 0

      if(!this.isMax && this.maxMoney >= this.orderPrice) {
        money = this.orderPrice - this.money
        ret = this.balance.convertMoneyToPoints(money)
        ret = Math.floor(ret)
      } else if(!this.isMax && this.maxMoney < this.orderPrice) {
        money = this.maxMoney - this.money
        ret = this.balance.convertMoneyToPoints(money)
        ret = Math.floor(ret)
      }

      return ret > 0 ? ret + this.pointsSymbol : 0 + this.pointsSymbol
    },

    title() {
      return this.$t('resto.checkout_review_loyalty_title')
    },

    thresholdRemainingAmount() {
      let ret = this.minRedemptionThreshold - this.balance.points
      return !this.$basil.isNil(ret) ? ret.toFixed(2) : ret
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  watch: {
    orderPrice() {
      this.onMaxClick()
      let ret = this.$basil.get(this.order, 'credits.money', null);
      if(!this.$basil.isNil(ret)) {
        this.money = ret
      }
    }
  },

  methods: {
    onCancel() {
      this.$store.commit('setErrors', { card: 'loyalty', values: [] })
      this.$store.commit('setActive', { card: 'loyalty', value: false })
    },

    onConfirm() {
      this.$store.commit('setErrors', { card: 'loyalty', values: [] })
      this.$store.commit('setLoading', { card: 'loyalty', value: true })

      this.$checkout.setLoyaltyCredits({ money: this.money })
        .then(() => {
          this.$store.commit('setActive', { card: 'loyalty', value: false })
          this.$store.commit('setValid', { card: 'loyalty', value: true })

          this.$store.commit('setLoading', { card: 'paymentMethod', value: true })
          this.$store.commit('setValid', { card: 'paymentMethod', value: false })
          this.$store.commit('setActive', { card: 'paymentMethod', value: false })
          this.$store.commit('sayl-front.checkout/setPaymentMethod', null)
          return this.$checkout.payment.find({})
        })
        .catch((e) => {
          $console.error(e)
          this.$store.commit('setErrors', { card: 'loyalty', values: e })
        })
        .finally(() => {
          this.$store.commit('setLoading', { card: 'loyalty', value: false })
          this.$store.commit('setLoading', { card: 'paymentMethod', value: false })
        })
    },

    onEdit() {
      this.$store.commit('setErrors', { card: 'loyalty', values: [] })
      this.$store.commit('setActive', { card: 'loyalty', value: true })
    },

    onInputPoint(value) {
      value = parseFloat(value);

      if(value > this.maxPoints) {
        this.points = this.maxPoints;
      } else {
        this.points = value
      }

      this.money = Math.ceil(this.balance.convertPointsToMoney(this.points));
    },

    onMoneyKeyup(e) {
      let input = this.$refs.money;
      let min = parseFloat(input.min || 0, 10)
      let max = parseFloat(input.max || 0, 10)
      let v = parseFloat(this.money, 10)
      this.money = parseFloat(v.toFixed(2), 10)
      this.money =  Math.min(max, Math.max(min, this.money))
      input.ivalue = this.money

      this.points = this.balance.convertMoneyToPoints(this.money);
      this.points = Math.floor(this.points.toFixed(2));
    },

    onPointKeyup(e) {
      let input = this.$refs.points;
      let min = parseFloat(input.min || 0, 10)
      let max = parseFloat(input.max || 0, 10)
      let v = parseFloat(this.points, 10)
      this.points = parseFloat(v.toFixed(2), 10)
      this.points = Math.floor(Math.min(max, Math.max(min, this.points)))
      input.ivalue = this.points

      this.money = this.balance.convertPointsToMoney(this.points);

      this.money = this.maxMoney < this.money ? this.maxMoney : this.money.toFixed(2);
    },

    onMaxClick() {
      this.points = this.maxPoints;
      this.money = this.maxMoney;
    }
  },

  mounted() {
    if(this.hasRedemption) {
      this.onMaxClick();
    } else {
      this.state.valid = true;
      this.state.active = false;
    }
  }
}
</script>

<template>
  <card class="checkout-terms">
    <!-- Read -->
    <template v-slot:read>

      <article class="checkout-card__article -column flow">
        <notice-banner
          :description="$t('resto.checkout_review_end_edition')"
          intent="danger"
          v-if="triggered"
        />

        <!-- Terms -->
        <forms-checkbox
          v-model="user.optInTc"
          @change="onChange">
          <div class="view-checkout-review__terms-content">
            <span
              class="view-checkout-review__terms"
              v-html="$t('resto.checkout_review_terms_label')"
            ></span>

            <span
              class="view-checkout-review__terms-link"
              @click.stop.prevent="() => showTerms = true"
              v-html="$t('resto.checkout_review_terms_link')"
            ></span>
          </div>
        </forms-checkbox>

        <!-- Newsletter -->
        <div v-if="isNewsletterActive && !optinAlreadyAccepted">
          <forms-checkbox
            @change="onChange"
            v-model="optin">
            <div class="view-checkout-review__terms-content">
              <span
                class="view-checkout-review__terms"
                v-html="$t('resto.checkout_review_newsletter')"
              ></span>
            </div>
          </forms-checkbox>
        </div>
      </article>

      <article class="checkout-card__article">
        <!-- Confirmation button -->
        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="checkout-card__cta -fill"
          :disabled="!isValid"
          :key="key"
          @click="onConfirm"
        >{{ orderLabel }}</actions-button>
      </article>

      <modal-terms
        :visible="showTerms"
        @close="() => showTerms = false"
        v-if="showTerms"
      />
    </template>
  </card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import MixinFields from '../../mixins/fields'
import MixinServices from '../../mixins/services'

import Card from './card'

import ModalTerms from '@/components/modal/terms'

export default {
  name: 'CheckoutTermsCard',

  components: {
    Card,
    ModalTerms,
  },
  extends: Card,

  inject: [
    '$checkout',
    '$user',
  ],

  mixins: [
    MixinFields,
    MixinServices,
  ],

  data() {
    return {
      key: 1,
      showTerms: false,
      triggered: false,
      optin: false,
    }
  },

  computed: {
    ...mapState({
      order: state => state['sayl-front.checkout'].order,
      pm: state => state['sayl-front.checkout'].paymentMethod,
      service: state => state['sayl-front.service'].service,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat',
      isOneActive: 'isOneActive'
    }),

    orderLabel() {
      let ret = this.$t('resto.checkout_order_label');

      if(this.$basil.get(this.pm, 'slug') === 'cash' || this.$basil.get(this.order, 'price_total', null) === 0) {
        ret = this.$t('resto.checkout_place_order_label');
      } else if(!this.$basil.get(this.pm, 'slug') === 'cash' || this.$basil.get(this.order, 'price_total', null) > 0) {
        ret = this.$t('resto.checkout_go_to_payment_label');
      }

      return ret
    },

    isNewsletterActive() {
      return this.isFieldActive('has_newsletter')
    },

    isNewsletterOptout() {
      return this.isFieldActive('is_newsletter_opt_out')
    },

    isTCOptout() {
      return this.isFieldActive('is_terms_opt_out')
    },

    // label() {
    //   let ret = this.$t('resto.checkout_review_terms')
    //   let url = this.$basil.get(this.oat, 'custom_tc_url')

    //   if(!this.$basil.isNil(url)) {
    //     ret = this.$t('resto.checkout_review_terms_with_link', { link: url })
    //   }

    //   return ret
    // },

    optinAlreadyAccepted() {
      return this.$basil.get(this.user, 'optIn', false)
    },

    isValid() {
      return this.key && this.user.optInTc
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods:{
    onChange() {
      this.key++;
    },

    showTermsModal() {
      this.showTerms = !this.showTerms
    },

    onConfirm(){
      if (this.isOneActive){
        this.triggered = true;
        return
      }

      this.user.optIn = this.optin

      this.triggered = false;

      this.$bus.$emit('injekt.sp.payment');

      let tasks = [
        // this.$user.update.bind(this.$user, {}),
        // this.$checkout.updateAuthInfo.bind(this.$checkout, { comment: this.order.comment, user: this.user }),
        this.$checkout.payment.apply.bind(this.$checkout.payment, { order: this.order }),
        this.$checkout.confirm.bind(this.$checkout, {}),
      ]

      this.$store.commit('sayl-front.checkout/setLoading', true)
      this.$basil.sequence(tasks)
        .catch((error) => {
          $console.error(error)

          let status = this.$basil.get(error, 'response.status', 500)
          let message = this.$basil.get(error, 'response.data.message', null)

          if(status === 422 && message === 'timeslot_is_not_available') {
            this.$store.commit('setErrors', { card: 'term', values: {timeslot: [this.$t(`resto.${message}`)]} })
            this.$store.commit('setValid', { card: 'timeslot', value: false })
            this.$store.commit('setActive', { card: 'timeslot', value: true })

            this.$notification({
              title: this.$t('resto.error_confirm_timeslot_passed_title'),
              message: this.$t('resto.error_confirm_timeslot_passed_description'),
              type: 'error',
              duration: 10
            })
          } else {
            error && this.$notification({
              title: this.$t('resto.error_confirm_title'),
              message: this.$t('resto.error_confirm_description'),
              type: 'error'
            });
          }
        })
        .finally(() => this.$store.commit('sayl-front.checkout/setLoading', false))
    },
  },

  mounted() {
    this.user.optInTc = this.isTCOptout;
    this.optin = this.user.optIn || this.isNewsletterOptout;
    this.key++;
  }
}
</script>

<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners"
  >
    <qrcode-stream
      class="modal-qr-scan__camera"
      @detect="onScan"
      v-if="!cameraError"
    />

    <data-empty
      :appearance="$pepper.Appearance.CARD"
      class="modal-qr-scan__error"
      :description="$t('resto.camera_error_description')"
      :size="$pepper.Size.S"
      :title="$t('resto.camera_error_title')"
      v-else
    />

    <template v-slot:footer>
      <actions-button
        @click="back"
        :size="$pepper.Size.S"
      >{{ $t('resto.cancel') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'QrScanModal',

  components: {
    LayoutModal,
    QrcodeStream,
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      cameraError: false,
    }
  },

  computed: {
    attrs() {
      return {
        classes: {
          'modal-qr-scan': true
        },
        title: this.$t('resto.modal_qr_scan_title'),
        visible: this.visible
      }
    },

    listeners() {
      return {
        close: this.back,
        back: this.back,
        discard: this.back,
      }
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    async init() {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true })
      } catch(e) {
        $console.error(e)
        this.cameraError = true
      }
    },

    async onScan(result) {
      this.cameraError = false
      try {
        let { content } = await result
        this.$emit('scan', content)
      } catch(e) {
        $console.error(e)
        this.cameraError = true
      }
    }
  },

  mounted() {
    this.init()
  }
}
</script>

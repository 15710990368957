import { mapGetters, mapState } from 'vuex'

import ReviewAuth from '../components/review/auth.vue'
import ReviewDelivery from '../components/review/delivery.vue'
import ReviewTerms from '../components/review/terms.vue'
import ReviewTimeslots from '../components/review/timeslots.vue'
import ReviewTeaser from '../components/review/teaser'
import ReviewVoucher from '../components/review/voucher.vue'
import ReviewPaymentMethods from '../components/review/payment-methods.vue'
import ReviewLoyalty from '../components/review/loyalty.vue'
import ReviewSaylWallet from '../components/review/sayl-wallet.vue'
import ReviewAppNotifications from '../components/review/notifications.vue'

export default {
  inject: [
    '$core',
    '$embed',
    '$localStorage',
    '$loyalty',
    '$user',
  ],

  computed: {
    ...mapState({
      authState: state => state.checkout.auth,
      deliveryState: state => state.checkout.delivery,
      loyaltyState: state => state.checkout.loyalty,
      paymentMethodState: state => state.checkout.paymentMethod,
      teaserLoyaltyState: state => state.checkout.teaser.loyalty,
      teaserWalletState: state => state.checkout.teaser.wallet,
      termState: state => state.checkout.term,
      timeslotState: state => state.checkout.timeslot,
      voucherState: state => state.checkout.voucher,
      invoiceState: state => state.checkout.invoice,
      saylWalletState: state => state.checkout.saylWallet,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    cards() {
      return [
        {
          attrs: this.timeslotState,
          component: ReviewTimeslots,
          name: 'timeslot',
          requires: !this.isEatin && !this.isVirtual,
          services: ['delivery', 'pickup']
        },
        {
          attrs: this.teaserLoyaltyState,
          component: ReviewTeaser,
          name: 'teaser',
          requires: this.shopHasLoyalty && (((this.isPickup || this.isDelivery) && this.timeslotState.valid) || this.isEatin) && !this.isVirtual && !this.isModeTt,
          services: ['delivery', 'eatin', 'pickup']
        },
        {
          attrs: this.authState,
          component: ReviewAuth,
          name: 'auth',
          requires: this.isEatin || this.isVirtual || (!this.isEatin && this.timeslotState.valid),
          services: ['delivery', 'eatin', 'pickup']
        },
        {
          attrs: this.deliveryState,
          component: ReviewDelivery,
          name: 'delivery',
          requires: this.isDelivery && this.authState.valid,
          services: ['delivery']
        },
        {
          attrs: this.voucherState,
          component: ReviewVoucher,
          name: 'voucher',
          requires: this.isVoucherCardShown,
          services: ['delivery', 'eatin', 'pickup', 'virtual']
        },
        {
          attrs: this.teaserWalletState,
          component: ReviewTeaser,
          name: 'teaser.wallet',
          requires: this.hasWallet && !this.isModeTt && this.isWalletEnabled,
          services: ['delivery', 'eatin', 'pickup']
        },
        {
          attrs: this.saylWalletState,
          component: ReviewSaylWallet,
          name: 'saylWallet',
          requires: this.hasWallet && !this.isModeTt && this.isWalletEnabled,
          services: ['delivery', 'eatin', 'pickup']
        },
        {
          attrs: this.loyaltyState,
          component: ReviewLoyalty,
          name: 'loyalty',
          requires: this.hasLoyalty && this.allowPointsRedemption && this.shopHasLoyalty && ((this.isDelivery && this.deliveryState.valid) || (!this.isDelivery && this.authState.valid)) && !this.isVirtual,
          services: ['delivery', 'eatin', 'pickup']
        },
        {
          attrs: this.paymentMethodState,
          component: ReviewPaymentMethods,
          name: 'paymentMethod',
          requires: (this.isVirtual && this.authState.valid) || (this.isDelivery && this.deliveryState.valid) || (!this.isDelivery && this.authState.valid),
          services: ['delivery', 'eatin', 'pickup', 'virtual']
        },
        {
          attrs: this.invoiceState,
          component: ReviewVoucher,
          name: 'invoice',
          requires: this.isInvoiceCardShown,
          services: ['delivery', 'eatin', 'pickup', 'virtual']
        },
        {
          attrs: this.timeslotState,
          component: ReviewAppNotifications,
          name: 'notifications',
          requires: this.isNotificationsCardShown,
          services: ['delivery', 'eatin', 'pickup', 'virtual']
        },
        {
          attrs: this.termState,
          component: ReviewTerms,
          name: 'term',
          requires: this.isTermCardShown,
          services: ['delivery', 'eatin', 'pickup', 'virtual']
        },
      ]
    },

    displayedCards() {
      let sn = this.$basil.get(this.service, 'name', null)
      let ret = this.key && [];

      ret = this.cards.filter(c => c.services.includes(sn))
      ret = this.cards.filter(c => c.requires === true)
      ret.length = ret.indexOf(ret.find(r => !r.attrs.valid)) + 1
      return ret
    },

    isLogged() {
      return this.$basil.get(this.user, 'isLogged', false);
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin';
    },

    isInvoiceCardShown() {
      return (
        this.paymentMethodState.valid
        && this.$basil.get(this.paymentMethod, 'slug', '') === 'invoice'
      );
    },

    isNotificationsCardShown() {
      return (
        this.isTermCardShown
        && this.$localStorage.context === 'mobile'
        && this.$localStorage['app-notifications'] !== 'granted'
        && window.ReactNativeWebView
        && this.isLogged
      )
    },

    isTermCardShown() {
      return (
        (this.paymentMethodState.valid && this.$basil.get(this.paymentMethod, 'slug', '') === 'invoice' && this.invoiceState.valid)
        || (this.paymentMethodState.valid && this.$basil.get(this.paymentMethod, 'slug', '') !== 'invoice')
      )
    },

    isVoucherCardShown() {
      return (
        (this.$basil.get(this.oat, 'is_voucher_enabled', true))
        && ((this.isDelivery && this.deliveryState.valid) || (!this.isDelivery && this.authState.valid))
        //&& !this.isVirtual
        && this.$basil.get(this.paymentMethod, 'slug', '') !== 'invoice'
      )
    },

    isWalletEnabled() {
      return this.$basil.get(this.oat, 'is_conn3ct_wallet_enabled', false) || false
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false
      
      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      let userBalance = this.$basil.get(this.$user, 'user.balance', null)
      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        !this.isModeTt,
        !isShopExcluded,
        !this.$basil.isNil(userBalance),
      ].filter(r => r === false).length === 0
    },

    allowPointsRedemption() {
      return this.$basil.get(this.$loyalty, 'program.allowPointsRedemption', true)
    },

    hasWallet() {
      return !this.$basil.isNil(window.conn3ct) && this.$basil.get(this.$embed, 'embed.model.activateSaylWallet', false)
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    shopHasLoyalty() {
      let loyalty = this.$basil.get(this.$loyalty, 'program', null);
      let excludedShopIds = this.$basil.get(loyalty, 'excludedShopIds', []) || [];
      let shopId = this.$basil.get(this.shop, 'id', null);
      return !excludedShopIds.includes(shopId) && !this.$basil.isNil(loyalty);
    }
  },
}

<template>
  <card
    class="checkout-delivery"
    v-bind="state"
    @cancel="onCancel"
    @confirm="onConfirm">

    <template v-slot:header>
      <h2 class="checkout-card__title">{{ title }}</h2>

      <actions-button
        v-if="!active"
        class="shop__cta"
        @click="onEdit"
        size="s"
      >{{$t('resto.change')}}</actions-button>
    </template>

    <!-- Read -->
    <template v-slot:read>
      <!-- Address -->
      <article class="checkout-card__article">
        <div class="checkout-card__illustration">
          <ui-icon glyph="location"></ui-icon>
        </div>

        <div class="checkout-card__inner">
          <address class="checkout-card__address">{{ address }}</address>
        </div>
      </article>

      <!-- Methods -->
      <article class="checkout-card__article">
        <div class="checkout-card__illustration">
          <ui-icon glyph="delivery"></ui-icon>
        </div>

        <div class="checkout-card__inner">
          <forms-choice
            @change="onChangeDeliveryMethod"
            v-if="deliveryMethods.length > 0"
            :options="deliveryMethods"
            v-model="method" />

          <div
            v-else
            class="checkout-card__sublabel"
          >{{ deliveryMethodsError }}</div>
        </div>
      </article>
    </template>

    <!-- Edit -->
    <template v-slot:edit>
      <article
        v-if="errored"
        class="checkout-card__article">
        <notice-banner
          :description="$t('resto.checkout_review_address_error')"
          intent="danger"
        />
      </article>

      <forms-address
        class="checkout-card__article -column"
        :address="value"
        :errors="errors"
      />
    </template>
  </card>
</template>

<script>
import { mapState } from 'vuex'

import Card from './card'
import MixinCurrency from '@/helpers/currency'
import FormsAddress from '@/modules/user/components/forms/address'

export default {
  name: 'CheckoutReviewDelivery',

  components: {
    Card,
    FormsAddress,
  },

  extends: Card,

  inject: [
    '$checkout',
    '$user',
  ],

  mixins: [
    MixinCurrency
  ],

  data(){
    return {
      key: 0,
      value: {},
      method: {},
    }
  },

  computed: {
    ...mapState({
      methods: state => state['sayl-front.checkout'].deliveryMethods,
      order: state => state['sayl-front.checkout'].order,
      state: state => state['checkout'].delivery,
    }),

    address() {
      let ret = (this.addresses || []).find(a => a.isDefault === true || a.is_default === true)

      if(this.$basil.isNil(ret)) {
        ret = this.$basil.get(this.user, 'addresses.0', null)
      }

      return ret
    },

    addresses() {
      let ret = this.$basil.get(this.user, 'addresses', [])
      return ret
    },

    deliveryMethods() {
      let ret = this.methods || []
      return ret.map(r => {
        let label = r.name

        if(r.price >= 0) {
          label += ' (+' + this.$n(r.price, 'currency') + ')'
        }
        return Object.assign(r, { label, value: r.id })
      })
    },

    deliveryMethodsError() {
      let hints = this.$basil.get(this.$checkout, 'deliveryMethods.hints', null);
      let ret = this.$t('resto.checkout_review_delivery_methods_loadingfailed');

      if(!this.$basil.isNil(hints)) {
        let minAmount = this.$basil.get(hints, 'min_amount', null)
        if(!this.$basil.isNil(minAmount)) {
          minAmount = this.toCurrency(minAmount);
          let zip = this.$basil.get(this.address, 'zip', null)
          ret = this.$t('resto.checkout_review_delivery_methods_min_amount_error', { min_amount: minAmount, zip })
        }
      }

      return ret
    },

    hasAddress(){
      return !this.$basil.isNil(this.$basil.get(this.address, 'id'))
    },

    hasAddresses(){
      return this.addresses.length > 0
    },

    timeslot(){
      return this.$basil.get(this.order, 'pickup_date')
    },

    title() {
      return this.$t('resto.checkout_review_delivery_address')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    }
  },

  watch: {
    'order.pickup_date': {
      handler(n, o){
        this.reset()
      }
    }
  },

  methods: {
    isValid() {
      let errors = {};

      if(this.$basil.isNil(this.value.street) || this.$basil.isEmpty(this.value.street)) {
        errors['street'] = [this.$t('resto.field_is_required')]
      }

      if(this.$basil.isNil(this.value.number) || this.$basil.isEmpty(this.value.number)) {
        errors['number'] = [this.$t('resto.field_is_required')]
      }

      if(this.$basil.isNil(this.value.country_id) || this.$basil.isEmpty(this.value.country_id)) {
        errors['country'] = [this.$t('resto.field_is_required')]
      }

      if(this.$basil.isNil(this.value.city) || this.$basil.isEmpty(this.value.city)) {
        errors['city'] = [this.$t('resto.field_is_required')]
      }

      if(this.$basil.isNil(this.value.zip) || this.$basil.isEmpty(this.value.zip)) {
        errors['zip'] = [this.$t('resto.field_is_required')]
      }

      let errorsKeys = Object.keys(errors);
      let ret = errorsKeys.length === 0

      if(!ret) {
        this.$store.commit('setErrors', { card: 'delivery', values: errors })
      }

      return ret;
    },

    onCancel(){
      this.$store.commit('setErrors', { card: 'delivery', values: [] })
      this.$store.commit('setActive', { card: 'delivery', value: false })
    },

    onConfirm() {
      this.$store.commit('setErrors', { card: 'delivery', values: [] })
      this.$store.commit('setLoading', { card: 'delivery', value: true })

      if(this.isValid()) {
        this.$user.setAddress({ address: this.value })
          .then(() => this.setCurrentAddress(this.address))
          .then(() => {
            this.$store.commit('setActive', { card: 'delivery', value: false })
            this.$store.commit('setValid', { card: 'delivery', value: false })
            this.$store.commit('setValid', { card: 'paymentMethod', value: false })
          })
          .catch((e) => {
            $console.error(e)
            this.$store.commit('setErrors', { card: 'delivery', values: e })
          })
          .finally(() => this.$store.commit('setLoading', { card: 'delivery', value: false }))
      } else {
        this.$store.commit('setLoading', { card: 'delivery', value: false })
      }
    },

    onChangeDeliveryMethod() {
      this.state.loading = true
      this.$checkout.deliveryMethods.setMethod({ id: this.method.id })
        .then(() => this.$store.commit('setValid', { card: 'delivery', value: true }))
        .catch(e => this.$store.commit('setErrors', { card: 'delivery', values: e }))
        .finally(() => this.$store.commit('setLoading', { card: 'delivery', value: false }))
    },

    onEdit() {
      this.$store.commit('setErrors', { card: 'delivery', values: [] })

      if(this.address == null) {
        this.value = {}
      } else {
        let i = { street: this.address.street, id: this.address.id, number: this.address.number, city: this.address.city, country: this.address.country, zip: this.address.zip}
        this.value = i
      }

      this.$store.commit('setActive', { card: 'delivery', value: true })
    },

    /**
     * Set the active address for the order
     */
    setCurrentAddress(a){
      return new Promise((resolve, reject) => {
        if (this.$basil.isNil(a)){
          return resolve()
        }

        this.$checkout.deliveryAddress.setAddress({ id: a.id })
          .then(() => this.refresh())
          .catch((e) => $console.error(e))
      })
    },

    refresh(){
      return new Promise((resolve, reject) => {
        this.$checkout.deliveryMethods.find({})
          .then(() => {
            this.$store.commit('setActive', { card: 'delivery', value: false })
            resolve();
          })
          .catch(e => {
            this.$store.commit('setErrors', { card: 'delivery', values: e })
            reject(e)
          })
          .finally(() => this.$store.commit('setLoading', { card: 'delivery', value: false }))
      })
    },

    reset(){
      this.$store.commit('setLoading', { card: 'delivery', value: true })

      // different address set it
      if(this.address && this.address !== this.value) {
        this.value = this.address
        this.setCurrentAddress(this.address)
      }
      // same address refresh the data
      else if (this.address && this.address === this.value){
        this.refresh()
      }
      else {
        this.$store.commit('setLoading', { card: 'delivery', value: false })
      }

      this.key++;
    },
  },

  mounted(){
    this.reset();
  },

  created() {
    this.$bus.$on('change-lang-delivery', () => this.reset())
  },

  beforeDestroy() {
    this.$bus.$off('change-lang-delivery')
  },
}
</script>

<template>
  <component
    class="checkout-teaser"
    :is="component"
  />
</template>

<script>
import TeaserLoyalty from './loyalty'
import TeaserWallet from './wallet'

export default {
  name: 'CheckoutReviewTeaser',

  props: {
    type: {
      required: true,
      type: String,
      validator: (value) => ['loyalty', 'wallet'].includes(value),
    }
  },

  computed: {
    component() {
      let ret = null;

      switch(this.type) {
        case 'wallet':
          ret = TeaserWallet
          break;

        case 'loyalty':
        default:
          ret = TeaserLoyalty
          break;
      }

      return ret
    },
  }
}
</script>

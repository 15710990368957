<template>
  <card class="checkout-notifications">
    <template v-slot:header>
      <div>
        <h2 class="checkout-card__title">{{ $t('resto.enable_app_notification_title') }}</h2>
        <div class="checkout-card__description" v-html="$t('resto.enable_app_notification_title')"></div>
      </div>

      <actions-button
        @click="requestNotificationAccess"
        class="shop__cta"
        size="s"
      >{{ $t('resto.enable_app_notification_action') }}</actions-button>
    </template>
  </card>
</template>

<script>
import Card from './card'

export default {
  name: 'CheckoutReviewNotification',

  components: {
    Card
  },

  extends: Card,

  inject: [
    '$localStorage'
  ],

  methods: {
    requestNotificationAccess() {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'notificationRequest' }))
    }
  }
}
</script>

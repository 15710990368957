<template>
  <card
    class="checkout-teaser-wallet"
    v-bind="state">

    <!-- header -->
    <template v-slot:header>
      <div class="checkout-teaser__header">
        <div class="checkout-teaser__row">
          <ui-icon
            class="checkout-card__illustration"
            glyph="rocket"
          />

          <actions-button
            class="checkout-teaser__cta -mobile"
            :size="$pepper.Size.S"
            @click="showWalletDescription = !showWalletDescription"
          >{{ $t('resto.show_wallet_description') }}</actions-button>
        </div>

        <div class="checkout-teaser__intro">
          <h2 class="checkout-card__title -has-action">
            <span
              class="checkout-teaser__value"
              v-html="title"></span>

            <actions-button
              class="checkout-teaser__cta -desktop"
              :size="$pepper.Size.S"
              @click="showWalletDescription = !showWalletDescription"
            >{{ $t('resto.show_wallet_description') }}</actions-button>
          </h2>
        </div>
      </div>
    </template>

    <template v-slot:subtitle>
      <article
        v-if="showWalletDescription"
        class="checkout-teaser-wallet__description">
        <p v-if="$basil.isNil(placeholderCheckout)">{{ $t('resto.wallet_description') }}</p>

        <p
          v-else
          v-html="placeholderCheckout"
        ></p>
      </article>
    </template>
  </card>
</template>

<script>
import { mapState } from 'vuex'

import Card from '../card'
import MixinTranslations from '@/helpers/translations'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'CheckoutReviewSaylWallet',

  mixins: [
    MixinTranslations,
    MixinCurrency,
  ],

  inject: [
    '$checkout',
    '$embed',
    '$user'
  ],

  components: {
    Card,
  },

  extends: Card,

  data() {
    return {
      claims: ['address', 'perks'],
      connectionTried: false,
      walletErrors: [],
      manualWalletClose: false,
      showWalletDescription: false
    }
  },

  computed: {
    ...mapState({
      state: state => state.checkout.teaser.wallet,
      wallet: state => state['sayl-front.checkout'].wallet,
      order: state => state['sayl-front.checkout'].order,
      states: state => state.bootstrap.states,
    }),

    discount() {
      return this.toCurrency(this.$basil.get(this.order, 'web3_trigger.discount') * - 1);
    },

    hasTrigger() {
      return !this.$basil.isNil(this.trigger);
    },

    hasWallet() {
      return !this.$basil.isNil(this.wallet);
    },

    isLoading(){
      return [this.states.APPLICATION_INIT, this.states.APPLICATION_AUTH, this.states.APPLICATION_MODULES].includes(this.status);
    },

    noPerks() {
      const errors = this.$basil.get(this.walletErrors, 'address', []);
      return errors.find(error => error === 'no_perks_linked');
    },

    placeholders() {
      return this.$basil.get(this.$embed, 'embed.model.placeholders', []) || [];
    },

    placeholderCheckout() {
      let ret = this.placeholders.find(p => p.key === 'wallet_description');

      return this.$basil.isNil(ret) ?
        null :
        this.$basil.get(ret, `content.${this.userLang}`, this.$basil.get(ret, 'content.all'));
    },

    title() {
      return this.$t('resto.checkout_teaser_sayl_wallet_title');
    },

    trigger() {
      return this.$basil.get(this.order, 'web3_trigger');
    },

    saylWallet() {
      return this.$basil.get(window, 'conn3ct');
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLang() {
      return this.$basil.get(this.user, 'lang', this.i18n.locale.lang.toString())
    },
  },

  methods: {
    disconnectWallet() {
      this.onEdit()

      this.connectionTried = false
      this.$store.commit('sayl-front.checkout/setWallet', null)

      this.$checkout.wallet.clear()
        .then(() => {})
        .catch((e) => $console.error(e))
    },

    connectWallet() {
      this.$store.commit('setLoading', { card: 'saylWallet', value: true });
    },

    onCancel(){
      this.code = '';
      this.$store.commit('setErrors', { card: 'saylWallet', values: [] });
      this.$store.commit('setActive', { card: 'saylWallet', value: false });
    },

    onConfirm(){
      if(this.$basil.isNil(this.wallet)) {
        this.$notification({
          title: this.$t('resto.checkout_review_no_sayl_wallet_title'),
          message: this.$t('resto.checkout_review_no_sayl_wallet_description'),
          type: 'error'
        })

        return;
      }

      this.$store.commit('setLoading', { card: 'saylWallet', value: true })
      this.$store.commit('setErrors', { card: 'saylWallet', values: [] })

      this.$checkout.wallet.add({ wallet: this.wallet })
        .then(() => {
          this.connectionTried = true;
          this.$store.commit('setLoading', { card: 'paymentMethod', value: true })
          return this.$checkout.payment.find({})
        })
        .then(() => {
          this.$store.commit('setActive', { card: 'saylWallet', value: false })
          this.$store.commit('setValid', { card: 'saylWallet', value: true })
        })
        .catch((error) => {
          $console.error(error)
          if (error.response){
            let errors = this.$basil.get(error, 'response.data.errors', {})
            let ks = Object.keys(errors);

            errors = ks.map(k => {
              return this.$t(`resto.checkout-saylWallet-${errors[k][0]}`)
            })
            this.$store.commit('setValid', { card: 'saylWallet', value: true });
            this.$store.commit('setErrors', { card: 'saylWallet', values: errors });
            return;
          }
        })
        .finally(() => {
          this.$store.commit('setLoading', { card: 'saylWallet', value: false })
          this.$store.commit('setLoading', { card: 'paymentMethod', value: false })
        })
    },

    onEdit() {
      this.$store.commit('setErrors', { card: 'saylWallet', values: [] })
    },

    scanWallet(data) {
      const token = this.$basil.get(data, 'token', this.$basil.get(data, 'detail.token'))

      this.$store.commit('setLoading', { card: 'saylWallet', value: true })
      this.$store.commit('sayl-front.checkout/setWallet', token)
      this.manualWalletClose = true

      this.saylWallet.disconnect()
        .then(() => this.saylWallet.close())
        .then(() => this.$checkout.wallet.add({ wallet: this.wallet }))
        .then(() => {
          this.$store.commit('setLoading', { card: 'paymentMethod', value: true });
          return this.$checkout.payment.find({})
        })
        .then(() => {
          this.$store.commit('setActive', { card: 'saylWallet', value: false })
          this.$store.commit('setValid', { card: 'saylWallet', value: true })
        })
        .catch((e) => {
          $console.error(e)

          let status = this.$basil.get(e, 'response.status', e.status)

          if(!this.$basil.isNil(status)) {
            switch(status) {
              case 404:
              case 422:
                let errors = this.$basil.get(e, '_items')
                this.$store.commit('setErrors', { card: 'saylWallet', values: errors });
                this.walletErrors = errors;
                this.$store.commit('setValid', { card: 'saylWallet', value: true });
              break

              case 500:
                this.$router.replace({ name: 'server-error', params: { entity } })
                break
            }
          }
        })
        .finally(() => {
          this.$store.commit('setLoading', { card: 'saylWallet', value: false })
          this.$store.commit('setLoading', { card: 'paymentMethod', value: false })
        })
    },

    onWalletClosed() {
      if(this.manualWalletClose) {
        return
      }

      this.$store.commit('setLoading', { card: 'saylWallet', value: false });
    },

    reset() {
      if(this.$basil.isNil(this.order.web3_address) && this.$basil.isNil(this.order.web3_trigger)) {
        return;
      }

      this.$store.commit('sayl-front.checkout/setWallet', this.order.web3_address);
    },
  },

  mounted() {
    this.reset();
  }
}
</script>

<template>
  <section :class="classes">
    <div class="checkout-card__content">

      <!-- Header -->
      <header
        v-if="hasHeader"
        class="checkout-card__header">
        <slot name="header"></slot>
      </header>

      <section
        v-if="hasSubtitle"
        class="checkout-card__subtitle"
      ><slot name="subtitle"></slot></section>

      <!-- Body -->
      <transitions-fade
        mode="out-in"
        direction="down">
        <!-- Edit -->
        <div
          v-if="active"
          key="edit"
          class="checkout-card__body checkout-card__edit">
          <slot name="edit"></slot>
        </div>

        <!-- Read -->
        <div
          v-if="!active && has('read')"
          key="read"
          class="checkout-card__body checkout-card__read">
          <slot name="read"></slot>
        </div>
      </transitions-fade>

      <!-- Loading -->
      <div
        v-if="loading"
        class="checkout-card__loader">
        <slot name="loading">
          <ui-loader />
        </slot>
      </div>
    </div>

    <!-- Footer -->
    <transitions-fade
      mode="out-in"
      direction="up">
      <footer
        v-if="active"
        class="checkout-card__footer">
        <slot name="footer">
          <actions-button
            appearance="subtle"
            @click="onCancel"
            :disabled="!valid"
          >{{ $t('resto.cancel') }}</actions-button>

          <actions-button
            appearance="primary"
            :loading="loading"
            @click="onConfirm"
          >{{ $t('resto.confirm') }}</actions-button>
        </slot>
      </footer>
    </transitions-fade>
  </section>
</template>

<script>
export default {
  name: 'CheckoutCard',

  props: {
    active: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Object|Array
    },

    loading: {
      type: Boolean,
      default: false
    },

    pristine: {
      type: Boolean,
      default: true
    },

    required: {
      type: Boolean,
      default: false
    },

    valid: {
      type: Boolean,
      default: true
    },
  },

  computed: {
    classes(){
      return {
        'checkout-card': true,

        '-is-active': this.active === true,
        '-is-loading': this.loading === true,
        '-is-errored': this.errored === true
      }
    },

    errored(){
      return !this.$basil.isNil(this.errors) && !this.$basil.isEmpty(this.errors)
    },

    hasHeader(){
      return this.has('header')
    },

    hasSubtitle() {
      return this.has('subtitle');
    }
  },

  methods: {
    has(name){
      let items = this.$slots[name];
      let ret = !!items || (items && items.length > 0)

      if (ret > 0){
        ret = items.find( i => i.isComment === false && (!!i.context || (i.text && i.text.trim().length > 0)) ) !== undefined
      }

      return !!ret;
    },

    onCancel(){
      return this.$emit('cancel')
    },

    onConfirm(){
      return this.$emit('confirm')
    }
  }
}
</script>

export default {
  methods: {
    getTranslation(obj) {
      if(typeof obj === 'string') {
        return obj;
      }

      return this.$basil.get(obj, this.userLang, this.$basil.get(obj, 'all', ''));
    }
  }
}

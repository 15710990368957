<template>
  <card
    class="checkout-voucher"
    :class="{ '-mb': !state.active && !hasVoucher }"
    :key="key"
    @cancel="onCancel"
    @confirm="onConfirm"
    v-bind="state">
    <template
      v-slot:header
      v-if="!hasVoucher">
      <h2 class="checkout-card__title">{{ title }}</h2>

      <div class="checkout-card__actions-group row">
        <actions-button
          icon-post="maximize"
          :size="$pepper.Size.S"
          @click="onEditAndScan"
          v-if="!hasVoucher && !state.active"
        >{{ $t('resto.checkout_review_voucher_scan') }}</actions-button>

        <actions-button
          icon-post="plus"
          :size="$pepper.Size.S"
          @click="onEdit"
          v-if="!hasVoucher && !state.active"
        >{{ $t('resto.checkout_review_voucher_add') }}</actions-button>
      </div>
    </template>

    <!-- Read -->
    <template v-slot:read>
      <!-- Voucher -->
      <article
        class="checkout-card__article checkout-voucher__voucher"
        v-if="hasVoucher">
        <div class="checkout-voucher__row">
          <div class="checkout-card__illustration">
            <ui-icon :glyph="`${isVoucher ? 'percent': 'license'}`"></ui-icon>
          </div>

          <div class="checkout-card__inner checkout-voucher__code -desktop">
            <span class="checkout-card__label checkout-voucher__code-name">{{ voucher.name }}</span>

            <data-badge
              v-if="isVoucher"
              class="checkout-voucher__code-discount"
              :value="discount"
            />
          </div>

          <actions-button
            class="checkout-voucher__cta"
            icon-post="minus"
            :size="$pepper.Size.S"
            @click="clear"
            v-if="hasVoucher"
          >{{ $t('resto.checkout_review_voucher_remove') }}</actions-button>
        </div>

        <div class="checkout-voucher__row -mobile">
          <div class="checkout-card__inner checkout-voucher__code">
            <span class="checkout-card__label checkout-voucher__code-name">{{ voucher.name }}</span>

            <data-badge
              v-if="isVoucher"
              class="checkout-voucher__code-discount"
              :value="discount"
            />
          </div>
        </div>
      </article>

      <!-- Remaining -->
      <article
        v-if="isWallet"
        class="checkout-card__article">
        <notice-banner
          class="shop__banner"
          :description="$t('resto.voucher_wallet_remaining', { amount: remainder })"
        />
      </article>
    </template>

    <!-- Edit -->
    <template v-slot:edit>
      <article class="checkout-card__article">
        <forms-input
          autofocus
          :disabled="state.loading"
          :errors="errors"
          @iconClick="onTriggerScan"
          icon-post="maximize"
          icon-post-interactive
          :placeholder="$t(`resto.checkout_review_${name}_placeholder`)"
          v-model="code"
          @keypress="onKey"
        />
      </article>

      <modal-qr-scan
        @close="onScanClose"
        @scan="onScan"
        :visible="qrScanVisible"
        v-if="qrScanVisible"
      />
    </template>
  </card>
</template>

<script>
import { mapState } from 'vuex'
import MixinCurrency from '@/helpers/currency'
import Card from './card'

import ModalQrScan from '@/components/modal/qr-scan'

export default {
  name: 'CheckoutReviewVoucherCard',

  props: {
    name: {
      type: String,
      default: 'voucher'
    }
  },

  inject: [
    '$checkout'
  ],

  components: {
    Card,
    ModalQrScan
  },

  mixins: [
    MixinCurrency
  ],

  data() {
    return {
      code: '',
      key: 1,
      qrScanVisible: false
    }
  },

  computed: {
    ...mapState({
      order: state => state['sayl-front.checkout'].order,
      voucher: state => state['sayl-front.checkout'].voucher,
      voucherState: state => state.checkout.voucher,
      invoiceState: state => state.checkout.invoice,
      paymentMethod: state => state['sayl-front.checkout'].paymentMethod,
    }),

    discount() {
      return this.toCurrency(this.voucher.amount_discount * -1)
    },

    errors() {
      let e = this.$basil.get(this.voucherState, 'errors._items.code', this.$basil.get(this.voucherState, 'errors._items.global', []))

      if(this.$basil.isNil(e)) {
        e = this.$basil.get(this.voucherState, 'errors', [])
      }

      return e ? e.map(ee => this.$t(`resto.checkout_${this.name}_${ee}`, this.errorsData)) : []
    },

    errorsData() {
      let voucher = this.$basil.get(this.voucherState, 'errors._data.voucher', null)

      let from = this.$basil.get(voucher, 'from', null)
      let to = this.$basil.get(voucher, 'to', null)
      let maxOrderAmount = null
      let minOrderAmount = null

      let conditions = this.$basil.get(voucher, 'conditions', [])
      if(!this.$basil.isNil(conditions)) {
        maxOrderAmount = conditions.find(c => c.type === 'max_order_amount')
        maxOrderAmount = !this.$basil.isNil(maxOrderAmount) ? this.toCurrency(maxOrderAmount.value) : null

        minOrderAmount = conditions.find(c => c.type === 'min_order_amount')
        minOrderAmount = !this.$basil.isNil(minOrderAmount) ? this.toCurrency(minOrderAmount.value) : null
      }

      return {
        nbrPerPerson: this.$basil.get(voucher, 'nbr_per_person', null),
        nbrTotal: this.$basil.get(voucher, 'nbr_total', null),

        from: !this.$basil.isNil(from) ? this.$basil.i18n.datetime(new Date(from)) : null,
        to: !this.$basil.isNil(to) ? this.$basil.i18n.datetime(new Date(to)) : null,

        min: minOrderAmount,
        max: maxOrderAmount,
      }
    },

    hasVoucher() {
      return !this.$basil.isNil(this.voucher)
    },

    isVoucher() {
      return this.name === 'voucher'
    },

    isWallet() {
      return this.$basil.get(this.voucher, 'type', 'default') === 'wallet'
    },

    remainder() {
      return this.toCurrency(this.voucher.amount_remaining)
    },

    state() {
      if(this.name === 'invoice') {
        return this.invoiceState
      }

      return this.voucherState
    },

    title() {
      return this.$t(`resto.checkout_review_${this.name}_title`)
    },
  },

  methods: {
    clear() {
      this.$store.commit('setLoading', { card: this.name, value: true })
      this.$store.commit('setErrors', { card: this.name, values: [] })

      this.$checkout.voucher.clear()
        .then(() => {
          this.$store.commit('setLoading', { card: 'paymentMethod', value: true })
          this.$store.commit('setValid', { card: 'paymentMethod', value: false })
          this.$store.commit('setActive', { card: 'paymentMethod', value: false })
          this.$store.commit('sayl-front.checkout/setPaymentMethod', null)
          return this.$checkout.payment.find({})
        })
        .then(() => {
          this.code = ''
          if(this.name === 'invoice') {
            this.$store.commit('setValid', { card: 'invoice', value: false })
            this.$store.commit('setActive', { card: 'invoice', value: true })
          }
          // this.$store.commit('setValid', { card: 'voucher', value: false})
        })
        .catch(e => this.$store.commit('setErrors', { card: this.name, values: this.errors }))
        .finally(() => {
          this.key++
          this.$store.commit('setLoading', { card: this.name, value: false })
          this.$store.commit('setLoading', { card: 'paymentMethod', value: false })
        })
    },

    onCancel() {
      this.code = ''
      this.$store.commit('setErrors', { card: this.name, values: [] })
      this.$store.commit('setActive', { card: 'voucher', value: false })
      this.$store.commit('setActive', { card: 'invoice', value: true })
    },

    onKey(event) {
      switch(event.keyCode) {
        case 13:
          this.onConfirm()
          break;
      }
    },

    reset() {
      if (!this.voucher) {
        return
      }

      this.$store.commit('setErrors', { card: this.name, values: [] })
      this.code = this.voucher.code
    },

    onConfirm() {
      this.$store.commit('setLoading', { card: this.name, value: true })
      this.$store.commit('setErrors', { card: this.name, values: [] })

      this.$checkout.voucher.add({ code: this.code })
        .then(() => {
          this.$store.commit('setLoading', { card: 'paymentMethod', value: true })
          return this.$checkout.payment.find({})
        })
        .then(() => {
          this.$store.commit('setActive', { card: this.name, value: false })
          this.$store.commit('setValid', { card: this.name, value: true })
        })
        .catch((error) => {
          $console.error(error)
          this.$store.commit('setErrors', { card: this.name, values: error })
        })
        .finally(() => {
          this.$store.commit('setLoading', { card: this.name, value: false })
          this.$store.commit('setLoading', { card: 'paymentMethod', value: false })
        })
    },

    onEdit() {
      this.$store.commit('setErrors', { card: this.name, values: [] })
      this.$store.commit('setActive', { card: this.name, value: true })
    },

    onEditAndScan() {
      this.onEdit()
      this.onTriggerScan()
    },

    onNativeMessage(e) {
      try {
        let { type, data } = JSON.parse(e.data)

        if(type === 'scan_done') {
          this.onScan(data)
        }
      } catch(e) {
        $console.error(e)
      }
    },

    onScan(code) {
      this.code = code
      this.$store.commit('setErrors', { card: this.name, values: [] })
      this.onScanClose()
    },

    onScanClose() {
      this.qrScanVisible = false
    },

    onTriggerScan() {
      if(!window.ReactNativeWebView) {
        this.qrScanVisible = true
        return
      }

      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'start_scan' }));
    }
  },

  mounted() {
    this.reset()

    if(this.name === 'invoice') {
      this.clear();
      this.$store.commit('setErrors', {card: 'invoice', value: []})
      this.$store.commit('setActive', {card: 'invoice', value: true})
    }

    if(window.ReactNativeWebView) {
      window.addEventListener('message', this.onNativeMessage)
      document.addEventListener('message', this.onNativeMessage)
    }
  },

  beforeDestroy() {
    if(window.ReactNativeWebView) {
      window.removeEventListener('message', this.onNativeMessage)
      document.removeEventListener('message', this.onNativeMessage)
    }
  }
}
</script>

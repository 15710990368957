<template>
  <card
    class="checkout-auth"
    v-bind="state"
    @cancel="onCancel"
    @confirm="onConfirm">
    <template
      v-slot:header
      v-if="!hasLoyalty || isVirtual">
      <h2 
        class="checkout-card__title" 
        v-if="(!isModeTt && !hasLoyalty) || isVirtual"
      >{{ title }}</h2>

      <actions-button
        class="shop__cta"
        size="s"
        @click="onEdit"
        v-if="!active"
      >{{ $t('resto.change') }}</actions-button>
    </template>

    <!-- Read -->
    <template v-slot:read>
      <!-- Logged -->
      <article class="checkout-card__article">
        <ui-user />

        <actions-button
          class="shop__cta"
          size="s"
          @click="onEdit"
          v-if="hasLoyalty && !isVirtual"
        >{{ $t('resto.change') }}</actions-button>
      </article>

      <article
        class="checkout-card__article"
        v-if="hasComment">
        <div class="checkout-card__illustration">
          <ui-icon glyph="message"></ui-icon>
        </div>

        <div class="checkout-card__inner row">
          <div class="checkout-card__label">{{ comment }}</div>
        </div>
      </article>
    </template>

    <!-- Edit -->
    <template v-slot:edit>
      <!-- Logged -->
      <article
        class="checkout-card__article -column flow"
        v-if="isLogged">
        <notice-banner
          :description="$t('resto.checkout_review_lost_password_successful')"
          intent="success"
          v-if="lostPasswordSuccess"
        />

        <ui-user />
      </article>

      <!-- Intro with sign in and sign up button -->
      <article
        class="checkout-card__article row"
        v-if="!isLogged && !isModeTt && (!hasLoyalty || isVirtual || requireAccount)">
        <p class="checkout-card__description">{{ introLabel }}</p>

        <actions-button
          :size="$pepper.Size.S"
          @click="onSignClick"
        >{{ introButtonLabel }}</actions-button>
      </article>

      <!-- Login form -->
      <article
        class="checkout-card__article"
        v-if="isLogin">
        <forms-login
          :errors="state.errors"
          @lost-password="onLostPasswordClick"
          v-model="user"
        />
      </article>

      <!-- Lost password form -->
      <article
        class="checkout-card__article"
        :key="key"
        v-if="isLostPassword">
        <forms-lost-password
          :errors="errors"
          @back="onLostPasswordDismiss"
          v-model="lostPasswordUser"
        />
      </article>

      <!-- Subscribe form -->
      <article
        class="checkout-card__article"
        v-if="isSubscribing">
          <!-- :autofocus="false" -->
        <forms-subscribe
          :key="key"
          :errors="state.errors"
          :has-guest="!requireAccount"
          v-model="user"
        />
      </article>

      <!-- Email exists -->
      <article
        class="checkout-card__article"
        v-if="hasLoyalty && emailAlreadyExists">
        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="checkout-card__full"
          @click="onSignIn"
        >{{ errors['email_exists'][0] }}</actions-button>
      </article>

      <!-- Comment -->
      <article
        class="checkout-card__article"
        v-if="isFieldCommentActive && (isLogged || (!isLostPassword && !isSignIn))">
        <forms-textarea
          :errors="errors['comment']"
          name="comment"
          :placeholder="$t('resto.checkout_review_comment_placeholder')"
          ref="comment"
          :rows="2"
          :value="comment"
          @input="onInputComment"
        >{{ $t('resto.checkout_review_comment') }}</forms-textarea>
      </article>
    </template>
  </card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MixinFields from '../../mixins/fields'
import MixinServices from '../../mixins/services'

import Card from './card'

import FormsLogin from '@/modules/user/components/forms/login'
import FormsLostPassword from '@/modules/user/components/forms/lost-password'
import FormsSubscribe from '@/modules/user/components/forms/subscribe'

import UiUser from './user'

export default {
  name: 'CheckoutReviewAuth',

  components: {
    Card,
    FormsLogin,
    FormsLostPassword,
    FormsSubscribe,
    UiUser,
  },
  extends: Card,

  inject: [
    '$checkout',
    '$core',
    '$embed',
    '$localStorage',
    '$loyalty',
    '$user',
  ],

  mixins: [
    MixinFields,
    MixinServices
  ],

  data() {
    return {
      isSignIn: false,
      key: 0,
      lostPassword: false,
      lostPasswordUser: {},
      lostPasswordSuccess: false,
    }
  },

  computed: {
    ...mapState({
      order: state => state['sayl-front.checkout'].order,
      state: state => state['checkout'].auth,
      checkoutLoading: state => state['sayl-front.checkout'].loading,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat'
    }),

    comment() {
      return this.$basil.get(this.order, 'comment')
    },

    emailAlreadyExists() {
      let ret = this.$basil.get(this.state, 'errors.email_exists', null)
      return !this.$basil.isNil(ret)
    },

    hasComment() {
      return !this.$basil.isNil(this.comment) && !this.$basil.isEmpty(this.comment)
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false
      let hasMoneyToPoints = this.$basil.get(this.$loyalty, 'program.rules.moneyToPoints', 0) > 0
      
      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        hasMoneyToPoints,
        !this.isModeTt,
        !isShopExcluded
      ].filter(r => r === false).length === 0
    },

    introButtonLabel() {
      let ret = this.$t('resto.checkout_review_sign_in');

      if(this.isSignIn && !this.isLostPassword) {
        ret = this.$t('resto.checkout_review_sign_up');
      }

      return ret
    },

    introLabel() {
      let ret = this.$t('resto.checkout_review_sign_in_description');

      if(this.isSignIn) {
        ret = this.$t('resto.checkout_review_sign_up_description');
      }

      if(this.isLostPassword) {
        ret = this.$t('resto.checkout_review_lost_password_description');
      }

      return ret
    },

    isCreating() {
      return this.$basil.get(this.user, 'isCreating')
    },

    isLogin() {
      return !this.lostPassword && this.isSignIn && !this.isLogged
    },

    isLogged() {
      return this.$basil.get(this.user, 'isLogged')
    },

    isLostPassword() {
      return this.lostPassword && !this.isLogged
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin';
    },

    isSubscribing() {
      return !this.lostPassword && !this.isSignIn && !this.isLogged
    },

    requireAccount() {
      return !!this.$basil.get(this.oat, 'disable_guest_checkout', false)
    },

    title() {
      let ret = this.$t('resto.checkout_review_about_you')

      if(this.hasLoyalty && !this.isVirtual) {
        ret = this.$t('resto.checkout_review_about_you_loyalty')
      }

      if(this.isVirtual) {
        ret = this.$t('resto.checkout_review_about_you')
      }

      if(!this.active) {
        ret = this.$t('resto.checkout_review_about_you_done')
      }

      return ret
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userEmail() {
      let ret = this.$basil.get(this.user, 'email')
      return ret
    },

    userName() {
      let ret = this.$basil.get(this.user, 'firstname');

      if(!this.$basil.isNil(this.user.lastname) && !this.$basil.isEmpty(this.user.lastname)) {
        ret += ` ${this.$basil.get(this.user, 'lastname').toUpperCase()}`
      }
      return ret
    },

    userPhone() {
      let ret = this.$basil.get(this.user, 'phone')
      return ret
    },
  },

  methods: {
    handleErrors(e, form = 'login') {
      $console.error(e)
      let errors = this.$basil.get(e, 'response.data.errors', null)
      let status = this.$basil.get(e, 'response.status', null)

      if(this.$basil.isNil(errors)) {
        errors = {}
      }

      let s = this.$basil.get(e, 'status', null)

      if(s === 422 && e.hasOwnProperty('_items')) {
        let errs = {}

        let keys = Object.keys(e._items)

        if(keys) {
          keys.map(k => {
            if('email_exists' !== this.$basil.get(e, `_items[${k}][0]`, 'errors')) {
              errs[k] = [this.$t('resto.' + this.$basil.get(e, `_items[${k}][0]`, 'errors'))]
            } else {
              errs['email_exists'] = [this.$t('resto.' + this.$basil.get(e, `_items[${k}][0]`, 'errors'))]
            }
          })
        }

        this.$store.commit('setErrors', { card: 'auth', values: errs })
        return
      }

      if(status === 401) {
        errors[form] = [this.$t('resto.checkout_review_login_failed')]
      } else {
        let phone = this.$basil.get(e, 'response.data.errors.phone.0', null);
        if (!this.$basil.isNil(phone)) {
          errors['phone'] = [phone]
          
          this.$notification({
            title: this.$t('resto.error_phone_number_title'),
            message: this.$t('resto.error_phone_number_description'),
            type: 'error'
          })
          // this.$refs.phone.$el.scrollIntoView();
        }

        Object.keys(errors).forEach(k => {
          errors[k] = errors[k].map(e => this.$t('resto.' + e))
        })
      }

      this.$store.commit('setErrors', { card: 'auth', values: errors })
    },

    isValid() {
      let errors = {}

      if(!this.isLogged) {
        if(this.isSignIn) {
          if(!this.isFieldEmailValid) {
            errors['email'] = [this.$t('resto.field_is_required')]
          }

          if(!this.isFieldPasswordValid) {
            errors['password'] = [this.$t('resto.field_is_required')]
          }
        } else {
          if(this.isFieldFirstnameRequired && !this.isFieldFirstnameValid) {
            errors['firstname'] = [this.$t('resto.field_is_required')]
          }

          if(this.isFieldLastnameRequired && !this.isFieldLastnameValid) {
            errors['lastname'] = [this.$t('resto.field_is_required')]
          }

          if(this.isFieldEmailRequired && !this.isFieldEmailValid) {
            let value = this.$basil.get(this.user, 'email', '') || ''
            let hasValue = value.trim().length > 0
            let isValid = this.emailPattern.test(value)
            errors['email'] = hasValue && !isValid ? [this.$t('resto.email_not_valid')] : [this.$t('resto.field_is_required')]
          }

          if(this.isFieldPhoneRequired && !this.isFieldPhoneValid) {
            errors['phone'] = [this.$t('resto.field_is_required')]
          }

          if(this.isCreating || this.requireAccount) {
            if(!this.isFieldPasswordValid) {
              errors['password'] = [this.$t('resto.field_is_required')]
            }

            if(!this.isFieldPasswordConfirmationValid) {
              errors['password_confirmation'] = [this.$t('resto.field_is_required')]
            }
          }
        }
      }

      let errorsKeys = Object.keys(errors)
      let ret = errorsKeys.length === 0

      if(!ret) {
        this.$store.commit('setErrors', { card: 'auth', values: errors })
      }

      return ret;
    },

    onCancel(){
      this.$store.commit('setErrors', { card: 'auth', values: [] })
      this.$store.commit('setActive', { card: 'auth', value: false })
    },

    onConfirm(){
      if(this.isLostPassword) {
        this.$store.commit('setLoading', { card: 'auth', value: true })
        if(!this.lostPasswordUser.isTokenShown) {
          this.$user.lostPassword({ email: this.lostPasswordUser.email })
            .then(() => this.lostPasswordUser.isTokenShown = true)
            .catch((e) => this.handleErrors(e, 'lost-password'))
            .finally(() => {
              this.key++
              this.$store.commit('setLoading', { card: 'auth', value: false })
            })
        } else {
          this.$user.changePassword({ email: this.lostPasswordUser.email, token: this.lostPasswordUser.token, password: this.lostPasswordUser.password, passwordConfirm: this.lostPasswordUser.passwordConfirm })
            .then(() => this.lostPasswordSuccess = true)
            .catch((e) => this.handleErrors(e, 'lost-password'))
            .finally(() => {
              this.key++
              this.$store.commit('setLoading', { card: 'auth', value: false })
            })
        }
      } else if(this.isValid()) {
        let prom = Promise.resolve.bind(Promise, null);

        if(this.isSignIn && !this.isLogged) {
          prom = this.$user.login.bind(this.$user, { email: this.user.email, password: this.user.password })
        } else if((this.isCreating || this.requireAccount) && !this.isLogged) {
          prom = this.$user.subscribe.bind(this.$user, { user: this.user, password: this.user.password, passwordConfirm: this.user.passwordConfirmation })
        } else if(!this.isLogged) {
          prom = this.$user.update.bind(this.$user, {  })
        }

        this.$store.commit('setLoading', { card: 'auth', value: true })
        this.$store.commit('setErrors', { card: 'auth', values: [] })
        prom()
          .then(() => this.$checkout.updateAuthInfo({ comment: this.order.comment, user: this.user }))
          .then(() => {
            let token = this.$localStorage['firebase-token']
            let context = this.$localStorage.context

            if(context === 'mobile' && token) {
              this.$user.setFirebaseToken({ token })
            }

            //sayl.injekt.sp.userId = this.user.id
            this.$store.commit('setValid', { card: 'auth', value: true })
            this.$store.commit('setActive', { card: 'auth', value: false })

            this.$localStorage.user = {
              date: new Date().toISOString(),
              email: this.user.email,
              firstname: this.user.firstname,
              lastname: this.user.lastname,
              phone: this.user.phone
            }

            $console.info('Checkout: User saved to localStorage')
          })
          .catch((e) => this.handleErrors(e))
          .finally(() => this.$store.commit('setLoading', { card: 'auth', value: false }))
      }
    },

    onEdit() {
      this.$store.commit('setActive', { card: 'auth', value: true })
    },

    onInputComment(value) {
      this.order.comment = value
    },

    onLogout() {
      let logout = () => {
        this.$store.commit('setLoading', { card: 'auth', value: true })

        this.$core.reset()
          .then(() => {
            this.$store.commit('setValid', { card: 'auth', value: false })
            this.$router.push({ name: 'sayl-front-catalog.catalog' }).catch(() => {})
          })
          .catch((e) => $console.error(e))
          .finally(() => this.$store.commit('setLoading', { card: 'auth', value: true }))
      }

      this.$confirm({
        title: this.$t('resto.logout_confirmation_title'),
        description: this.$t('resto.logout_confirmation_description'),
        onPrimary: logout
      })
    },

    onLostPasswordClick() {
      this.lostPassword = true;
      this.lostPasswordUser.isTokenShown = false;
      this.$store.commit('setErrors', { card: 'auth', values: {} })
    },

    onLostPasswordDismiss() {
      this.lostPassword = false;
      this.$store.commit('setErrors', { card: 'auth', values: {} })
    },

    onSignClick() {
      return (this.isSignIn && !this.isLostPassword ?
        this.onSignUp : this.onSignIn)()
    },

    onSignIn() {
      this.lostPassword = false
      this.$store.commit('setErrors', { card: 'auth', values: {} })
      this.isSignIn = true
      this.key++
    },

    onSignUp() {
      this.$store.commit('setErrors', { card: 'auth', values: {} })
      this.isSignIn = false
    },

    reset() {
      this.lostPasswordSuccess = false
      if(this.isLogged) {
        this.$store.commit('setLoading', { card: 'auth', value: true })
        this.$checkout.updateAuthInfo({ comment: this.comment, user: this.user })
          .then(() => {
            this.isGuest = false;

            if(this.isFieldCommentActive) {
              this.$store.commit('setValid', { card: 'auth', value: this.$basil.get(this.state, 'valid', false) })
              this.$store.commit('setActive', { card: 'auth', value: this.$basil.get(this.state, 'active', true) })
            } else {
              this.$store.commit('setValid', { card: 'auth', value: true })
              this.$store.commit('setActive', { card: 'auth', value: false })
            }
          })
          .catch((e) => $console.error(e))
          .finally(() => this.$store.commit('setLoading', { card: 'auth', value: false }))
      } else {
        this.$store.commit('setLoading', { card: 'auth', value: false })
        this.$store.commit('setValid', { card: 'auth', value: false })
        this.$store.commit('setActive', { card: 'auth', value: true })
      }
    },
  },

  mounted() {
    !this.checkoutLoading && this.reset()
    this.isModeTt && this.$user.reset()

    this.$bus.$on('sign-up', () => {
      this.user.isCreating = true;
      this.onSignUp()
      let el = document.getElementsByClassName('checkout-auth')[0]
      let offset = 4 * 16 * -1
      let y = el.getBoundingClientRect().y + offset + window.scrollY
      scrollTo({ top: y, behavior: 'smooth' })
      this.key++
    })

    this.$bus.$on('sign-in', () => {
      this.onSignIn()
      let el = document.getElementsByClassName('checkout-auth')[0]
      let offset = 4 * 16 * -1
      let y = el.getBoundingClientRect().y + offset + window.scrollY
      scrollTo({ top: y, behavior: 'smooth' })
    })
  },

  beforeDestroy() {
    this.$bus.$off('sign-up')
    this.$bus.$off('sign-in')
  }
}
</script>

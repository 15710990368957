<template>
  <card
    class="checkout-payment-methods"
    v-bind="state"
    @cancel="onCancel"
    @confirm="onConfirm">

    <template v-slot:header>
      <h2 class="checkout-card__title">{{ title }}</h2>
    </template>

    <!-- Read -->
    <template v-slot:read>
      <article class="checkout-card__article">
        <div class="checkout-card__illustration -desktop">
          <ui-icon glyph="euro"></ui-icon>
        </div>

        <!-- Has methods -->
        <div
          class="checkout-card__inner -reactive"
          v-if="hasValues">
          <forms-choice
            :key="key"
            @change="onChange"
            :options="list"
            v-model="value">
          </forms-choice>
        </div>

        <!-- No Payement methods -->
        <div
          class="checkout-card__inner"
          v-if="!hasValues">
          <p class="checkout-card__label">{{ $t('resto.checkout_review_payment_methods_loadingfailed') }}</p>
        </div>
      </article>
    </template>
  </card>
</template>

<script>
import { mapState } from 'vuex'
import Config from '@/config'

import MixinServices from '../../mixins/services'
import Card from './card'

export default {
  name: 'CheckoutReviewPaymentMethodsCard',

  components: {
    Card
  },
  extends: Card,

  mixins: [
    MixinServices
  ],

  inject: [
    '$checkout',
    '$embed',
  ],

  data() {
    return {
      value: null,
      key: 1
    }
  },

  computed: {
    ...mapState({
      checkoutLoading: state => state['sayl-front.checkout'].loading,
      paymentMethod: state => state['sayl-front.checkout'].paymentMethod,
      paymentMethods: state => state['sayl-front.checkout'].paymentMethods,
      order: state => state['sayl-front.checkout'].order,
      state: state => state['checkout'].paymentMethod,
      table: state => state['sayl-front.service-eatin'].table,
      i18n: state => state.bootstrap.i18n,
    }),

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    hasValues() {
      return !!this.list && this.list.length > 0;
    },

    list() {
      let ret = []

      if(!this.paymentMethods) {
        return ret
      }

      if(this.paymentMethods.length === 1 && this.order.base_price_total === 0) {
        ret.push({
          label: this.$t(`resto.${ this.paymentMethods[0].title }`),
          value:this.paymentMethods[0].slug,
          description: this.$t(`resto.${ this.paymentMethods[0].description }`)
        })

        return ret
      }

      this.paymentMethods.forEach(pm => {
        let i = {
          label: pm.title,
          value: pm.slug
        }

        if(!this.$basil.isNil(pm.description)) {
          i.description = this.$basil.get(pm, 'description')
        } else {
          i.description = this.$t(`resto.payment_methods_description_${pm.slug}`)
        }
        ret.push(i)
      })

      return ret
    },

    redirect() {
      let ret = [
        Config.publicPath,
        'checkout/confirm',
        this.order.id
      ]

      ret = ret.filter(e => e.trim().length > 0)
      ret = '/' + ret.join('/')

      let qs = [`service=${this.serviceName}`]
      if (this.isEatin && this.table && this.table.id) {
        qs.push(`table=${this.table.id}`)
      }
      qs = qs.join('&')

      ret = [ret, qs].join('?')
      ret = ret.split('\/\/').join('/')
      ret = ret.replace('//', '/')
      ret = encodeURIComponent(ret)

      return ret
    },

    title() {
      return this.$t('resto.checkout_review_payment_methods_title')
    }
  },

  methods: {
    onChange() {
      let slug = this.value.value
      let method = this.$checkout.payment.getMethodForSlug(slug)

      if (this.$basil.isNil(method)) {
        $console.warn('no payment method for slug', slug);
        return
      }

      this.$checkout.payment.setMethod({ method, redirect: this.redirect })
        .then(() => this.$store.commit('setValid', { card: 'paymentMethod', value: true}))
        .catch((e) => {
          $console.error(e)
          this.$store.commit('setValid', { card: 'paymentMethod', value: false})
        })
    },

    reset() {
      if (this.$basil.isNil(this.order) || this.checkoutLoading === true || this.state.loading === true) {
        return
      }

      this.$store.commit('setLoading', { card: 'paymentMethod', value: true })

      this.$checkout.payment.find({ locale: this.i18n.locale })
        .then(() => {
          if(this.serviceName == 'eatin' && !this.$basil.isNil(this.list) && this.list.length === 1 && this.$basil.get(this.list, '0.value', null) === 'cash') {
            this.value = this.list[0]
            this.onChange();
            this.key++
          } else {
            this.value = this.paymentMethod != null ? this.list.find(p =>  p.value === this.paymentMethod.slug) : null
            this.value != null && this.onChange()
            this.key++
          }
        })
        .catch(e => this.$store.commit('setErrors', { card: 'paymentMethod', values: e }))
        .finally(() => this.$store.commit('setLoading', { card: 'paymentMethod', value: false }))
    }
  },

  mounted() {
    this.reset()
  },

  created() {
    this.$bus.$on('change-lang-payments', () => this.reset())
  },

  beforeDestroy() {
    this.$bus.$off('change-lang-payments')
  }
}
</script>
